import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices

import snipeReducer from './slices/snipe';
import nftReducer from './slices/nft';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const snipePersistConfig = {
  key: 'snipe',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['snipe', 'snipes', 'snipeStats'],
};

const nftPersistConfig = {
  key: 'nft',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['nfts', 'nftUser', 'nftStats', 'isEnabled'],
};

const rootReducer = combineReducers({
  snipe: persistReducer(snipePersistConfig, snipeReducer),
  nft: persistReducer(nftPersistConfig, nftReducer),
});

export { rootPersistConfig, rootReducer };
