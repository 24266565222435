import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { MenuItem, Stack } from '@mui/material';

// components
import Iconify from '../../../components/Iconify';
import Image from '../../../components/Image';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';

// ----------------------------------------------------------------------
WalletPopover.propTypes = {
  tokenBalance: PropTypes.string,
  nativeBalance: PropTypes.string,
};

export default function WalletPopover({ tokenBalance, nativeBalance }) {
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = async () => {
    setOpen(null);
    window.open('https://app.uniswap.org/#/swap?outputCurrency=0x2180165b01eeaf93d27a3aa917bba26ae98f5a65');
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          ...(open && { bgcolor: 'action.selected' }),
        }}
      >
        <Iconify icon={'fa6-solid:coins'} width={20} height={20} />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={() => setOpen(null)}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 250,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <Stack spacing={0.75}>
          <MenuItem key="eth" onClick={() => handleClose()}>
            <Image disabledEffect alt="eth picture" src="/assets/icons/eth.png" sx={{ width: 34, mr: 2 }} />
            {nativeBalance} ETH
          </MenuItem>
          <MenuItem key="mxs" onClick={() => handleClose()}>
            <Image disabledEffect alt="mxs picture" src="/assets/icons/maxsniper.webp" sx={{ width: 36, mr: 2 }} />
            {tokenBalance} Maximus (Buy here)
          </MenuItem>
        </Stack>
      </MenuPopover>
    </>
  );
}
