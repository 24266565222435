export const nfts = [
  {
    id: '1',
    name: 'Maximus',
    stars: '2.44',
    rank: '4',
    score: '124.72',
    image: '/assets/nfts/1.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Factory',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Epic',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Ice',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Doge',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '7 Eyes',
        value: 'Nuke',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '2',
    name: 'Maximus',
    stars: '1.10',
    rank: '281',
    score: '42.730000000000004',
    image: '/assets/nfts/2.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Wind',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Spacy',
        rarity: '7.14',
        rarity_perc: '14.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Neon',
        rarity: '9.09',
        rarity_perc: '11.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Shotgun',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '3',
    name: 'Maximus',
    stars: '1.84',
    rank: '58',
    score: '88.03',
    image: '/assets/nfts/3.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Fire',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 555',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Spacy',
        rarity: '7.14',
        rarity_perc: '14.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Electro Gun',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '4',
    name: 'Maximus',
    stars: '1.59',
    rank: '115',
    score: '72.42',
    image: '/assets/nfts/4.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Clouds',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Doge',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '7 Eyes',
        value: 'Rocket',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '5',
    name: 'Maximus',
    stars: '1.51',
    rank: '128',
    score: '67.77000000000001',
    image: '/assets/nfts/5.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Aurora',
        rarity: '15.00',
        rarity_perc: '6.67',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 555',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Carbine',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '6',
    name: 'Maximus',
    stars: '1.94',
    rank: '33',
    score: '93.8',
    image: '/assets/nfts/6.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Factory',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Frosty',
        rarity: '7.50',
        rarity_perc: '13.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Forest',
        rarity: '8.33',
        rarity_perc: '12.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Shotgun',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Doge',
        rarity: '12.50',
        rarity_perc: '8.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '7',
    name: 'Maximus',
    stars: '1.32',
    rank: '186',
    score: '55.910000000000004',
    image: '/assets/nfts/7.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Gloom',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Epic',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Desert',
        rarity: '8.11',
        rarity_perc: '12.33',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Laser Beams',
        rarity: '14.29',
        rarity_perc: '7.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '8',
    name: 'Maximus',
    stars: '1.53',
    rank: '126',
    score: '68.65',
    image: '/assets/nfts/8.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Future',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Desert',
        rarity: '8.11',
        rarity_perc: '12.33',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Doge',
        rarity: '12.50',
        rarity_perc: '8.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '9',
    name: 'Maximus',
    stars: '1.38',
    rank: '159',
    score: '59.76',
    image: '/assets/nfts/9.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Sparks',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Camo',
        rarity: '6.98',
        rarity_perc: '14.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Ice',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Carbine',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '10',
    name: 'Maximus',
    stars: '1.33',
    rank: '178',
    score: '56.81',
    image: '/assets/nfts/10.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Gloom',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Camo',
        rarity: '6.98',
        rarity_perc: '14.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Paint',
        rarity: '10.00',
        rarity_perc: '10.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Railgun',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '11',
    name: 'Maximus',
    stars: '1.80',
    rank: '62',
    score: '85.22999999999999',
    image: '/assets/nfts/11.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Battlefront',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Frosty',
        rarity: '7.50',
        rarity_perc: '13.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Forest',
        rarity: '8.33',
        rarity_perc: '12.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Oldtimer',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
      {
        trait_type: '7 Eyes',
        value: 'Doge',
        rarity: '12.50',
        rarity_perc: '8.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '12',
    name: 'Maximus',
    stars: '1.15',
    rank: '255',
    score: '45.85',
    image: '/assets/nfts/12.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Clouds',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Meteor',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Forest',
        rarity: '8.33',
        rarity_perc: '12.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Plasma',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '13',
    name: 'Maximus',
    stars: '1.45',
    rank: '147',
    score: '63.9',
    image: '/assets/nfts/13.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Gold',
        rarity: '27.27',
        rarity_perc: '3.67',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Frosty',
        rarity: '7.50',
        rarity_perc: '13.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Toxic Blaster',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '14',
    name: 'Maximus',
    stars: '1.60',
    rank: '112',
    score: '73.02',
    image: '/assets/nfts/14.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Showroom',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 38',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Meteor',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Forest',
        rarity: '8.33',
        rarity_perc: '12.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Shotgun',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '15',
    name: 'Maximus',
    stars: '1.79',
    rank: '65',
    score: '84.63999999999999',
    image: '/assets/nfts/15.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Gold',
        rarity: '27.27',
        rarity_perc: '3.67',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 555',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Frosty',
        rarity: '7.50',
        rarity_perc: '13.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Desert',
        rarity: '8.11',
        rarity_perc: '12.33',
      },
      {
        trait_type: '6 Weapons',
        value: 'Oldtimer',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '16',
    name: 'Maximus',
    stars: '1.20',
    rank: '225',
    score: '48.93',
    image: '/assets/nfts/16.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Clouds',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Meteor',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Forest',
        rarity: '8.33',
        rarity_perc: '12.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Sniper',
        rarity: '23.08',
        rarity_perc: '4.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '17',
    name: 'Maximus',
    stars: '1.09',
    rank: '282',
    score: '42.370000000000005',
    image: '/assets/nfts/17.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Mist',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Camo',
        rarity: '6.98',
        rarity_perc: '14.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Bfg',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '18',
    name: 'Maximus',
    stars: '1.17',
    rank: '244',
    score: '47.01',
    image: '/assets/nfts/18.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Aurora',
        rarity: '15.00',
        rarity_perc: '6.67',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Camo',
        rarity: '6.98',
        rarity_perc: '14.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Forest',
        rarity: '8.33',
        rarity_perc: '12.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Flamer',
        rarity: '13.04',
        rarity_perc: '7.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '19',
    name: 'Maximus',
    stars: '1.34',
    rank: '174',
    score: '57.13000000000001',
    image: '/assets/nfts/19.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Mech War 2',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Neon',
        rarity: '9.09',
        rarity_perc: '11.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '20',
    name: 'Maximus',
    stars: '2.00',
    rank: '22',
    score: '97.71',
    image: '/assets/nfts/20.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Gold',
        rarity: '27.27',
        rarity_perc: '3.67',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 880',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Meteor',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Neon',
        rarity: '9.09',
        rarity_perc: '11.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Bfg',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '21',
    name: 'Maximus',
    stars: '1.50',
    rank: '133',
    score: '67.02000000000001',
    image: '/assets/nfts/21.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Mech War',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Desert',
        rarity: '8.11',
        rarity_perc: '12.33',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Laser Beams',
        rarity: '14.29',
        rarity_perc: '7.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '22',
    name: 'Maximus',
    stars: '1.75',
    rank: '73',
    score: '82.31',
    image: '/assets/nfts/22.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Future',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Ice',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Nuke',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '23',
    name: 'Maximus',
    stars: '1.17',
    rank: '238',
    score: '47.28',
    image: '/assets/nfts/23.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Clouds',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Meteor',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Forest',
        rarity: '8.33',
        rarity_perc: '12.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Oldtimer',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '24',
    name: 'Maximus',
    stars: '1.64',
    rank: '103',
    score: '75.55',
    image: '/assets/nfts/24.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Aurora',
        rarity: '15.00',
        rarity_perc: '6.67',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 555',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Spacy',
        rarity: '7.14',
        rarity_perc: '14.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Paint',
        rarity: '10.00',
        rarity_perc: '10.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Sniper',
        rarity: '23.08',
        rarity_perc: '4.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '25',
    name: 'Maximus',
    stars: '1.40',
    rank: '156',
    score: '60.8',
    image: '/assets/nfts/25.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Showroom',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Spacy',
        rarity: '7.14',
        rarity_perc: '14.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Paint',
        rarity: '10.00',
        rarity_perc: '10.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Sniper',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '26',
    name: 'Maximus',
    stars: '1.73',
    rank: '81',
    score: '80.94',
    image: '/assets/nfts/26.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Aurora',
        rarity: '15.00',
        rarity_perc: '6.67',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 38',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Frosty',
        rarity: '7.50',
        rarity_perc: '13.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Desert',
        rarity: '8.11',
        rarity_perc: '12.33',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Railgun',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '27',
    name: 'Maximus',
    stars: '1.73',
    rank: '77',
    score: '81.35',
    image: '/assets/nfts/27.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Showroom',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 555',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Meteor',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Forest',
        rarity: '8.33',
        rarity_perc: '12.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Assault',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '28',
    name: 'Maximus',
    stars: '1.35',
    rank: '166',
    score: '58.07',
    image: '/assets/nfts/28.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Mist',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Neon',
        rarity: '9.09',
        rarity_perc: '11.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Nuke',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '29',
    name: 'Maximus',
    stars: '1.67',
    rank: '93',
    score: '77.21000000000001',
    image: '/assets/nfts/29.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Factory',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Epic',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Doge',
        rarity: '12.50',
        rarity_perc: '8.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '30',
    name: 'Maximus',
    stars: '1.75',
    rank: '72',
    score: '82.6',
    image: '/assets/nfts/30.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Sparks',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 38',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Frosty',
        rarity: '6.82',
        rarity_perc: '14.67',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Railgun',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '31',
    name: 'Maximus',
    stars: '1.13',
    rank: '267',
    score: '44.370000000000005',
    image: '/assets/nfts/31.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Wind',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Neon',
        rarity: '9.09',
        rarity_perc: '11.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Sniper',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '32',
    name: 'Maximus',
    stars: '1.51',
    rank: '129',
    score: '67.69',
    image: '/assets/nfts/32.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Showroom',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 38',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Frosty',
        rarity: '7.50',
        rarity_perc: '13.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Frosty',
        rarity: '6.82',
        rarity_perc: '14.67',
      },
      {
        trait_type: '6 Weapons',
        value: 'Flamer',
        rarity: '13.04',
        rarity_perc: '7.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '33',
    name: 'Maximus',
    stars: '1.87',
    rank: '45',
    score: '89.67999999999999',
    image: '/assets/nfts/33.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Showroom',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 880',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Meteor',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Forest',
        rarity: '8.33',
        rarity_perc: '12.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Bfg',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '34',
    name: 'Maximus',
    stars: '1.65',
    rank: '99',
    score: '76.09',
    image: '/assets/nfts/34.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Gold',
        rarity: '27.27',
        rarity_perc: '3.67',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 555',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Paint',
        rarity: '10.00',
        rarity_perc: '10.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Flamer',
        rarity: '13.04',
        rarity_perc: '7.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '35',
    name: 'Maximus',
    stars: '1.61',
    rank: '111',
    score: '73.73',
    image: '/assets/nfts/35.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Toxic',
        rarity: '17.65',
        rarity_perc: '5.67',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 555',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Frosty',
        rarity: '7.50',
        rarity_perc: '13.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Frosty',
        rarity: '6.82',
        rarity_perc: '14.67',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Carbine',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '36',
    name: 'Maximus',
    stars: '1.29',
    rank: '195',
    score: '54.22',
    image: '/assets/nfts/36.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Gloom',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Frosty',
        rarity: '6.82',
        rarity_perc: '14.67',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Moneybag',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '37',
    name: 'Maximus',
    stars: '1.53',
    rank: '125',
    score: '68.98',
    image: '/assets/nfts/37.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Battlefront',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Ice',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Laser Eyes',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '38',
    name: 'Maximus',
    stars: '1.28',
    rank: '199',
    score: '53.79',
    image: '/assets/nfts/38.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Clouds',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Spacy',
        rarity: '7.14',
        rarity_perc: '14.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Frosty',
        rarity: '6.82',
        rarity_perc: '14.67',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Railgun',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '39',
    name: 'Maximus',
    stars: '1.28',
    rank: '200',
    score: '53.63',
    image: '/assets/nfts/39.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Wind',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Camo',
        rarity: '6.98',
        rarity_perc: '14.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Frosty',
        rarity: '6.82',
        rarity_perc: '14.67',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Railgun',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '40',
    name: 'Maximus',
    stars: '1.18',
    rank: '236',
    score: '47.370000000000005',
    image: '/assets/nfts/40.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Wind',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Camo',
        rarity: '6.98',
        rarity_perc: '14.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bubble Gun',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '41',
    name: 'Maximus',
    stars: '1.86',
    rank: '47',
    score: '89.33000000000001',
    image: '/assets/nfts/41.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Toxic',
        rarity: '17.65',
        rarity_perc: '5.67',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 880',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Frosty',
        rarity: '7.50',
        rarity_perc: '13.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Neon',
        rarity: '9.09',
        rarity_perc: '11.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Oldtimer',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '42',
    name: 'Maximus',
    stars: '1.87',
    rank: '46',
    score: '89.41',
    image: '/assets/nfts/42.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Future',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Frosty',
        rarity: '6.82',
        rarity_perc: '14.67',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Sniper',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
      {
        trait_type: '7 Eyes',
        value: 'Rocket',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '43',
    name: 'Maximus',
    stars: '1.26',
    rank: '208',
    score: '52.53',
    image: '/assets/nfts/43.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Clouds',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Spacy',
        rarity: '7.14',
        rarity_perc: '14.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Shotgun',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '44',
    name: 'Maximus',
    stars: '1.13',
    rank: '268',
    score: '44.31999999999999',
    image: '/assets/nfts/44.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Gloom',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Frosty',
        rarity: '7.50',
        rarity_perc: '13.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Toxic Blaster',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '45',
    name: 'Maximus',
    stars: '1.70',
    rank: '85',
    score: '79.30000000000001',
    image: '/assets/nfts/45.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Fire',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 38',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Camo',
        rarity: '6.98',
        rarity_perc: '14.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Futu Rifle',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '46',
    name: 'Maximus',
    stars: '1.62',
    rank: '110',
    score: '74.54',
    image: '/assets/nfts/46.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Toxic',
        rarity: '17.65',
        rarity_perc: '5.67',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 38',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Camo',
        rarity: '11.11',
        rarity_perc: '9.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Plasma',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '47',
    name: 'Maximus',
    stars: '1.23',
    rank: '217',
    score: '50.41',
    image: '/assets/nfts/47.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Gloom',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Frosty',
        rarity: '7.50',
        rarity_perc: '13.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Paint',
        rarity: '10.00',
        rarity_perc: '10.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Sniper',
        rarity: '23.08',
        rarity_perc: '4.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '48',
    name: 'Maximus',
    stars: '1.45',
    rank: '149',
    score: '63.8',
    image: '/assets/nfts/48.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Mist',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Spacy',
        rarity: '7.14',
        rarity_perc: '14.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Camo',
        rarity: '11.11',
        rarity_perc: '9.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Toxic Blaster',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
      {
        trait_type: '7 Eyes',
        value: 'Laser Beams',
        rarity: '14.29',
        rarity_perc: '7.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '49',
    name: 'Maximus',
    stars: '1.03',
    rank: '292',
    score: '38.25',
    image: '/assets/nfts/49.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Wind',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Meteor',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Ice',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Flamer',
        rarity: '13.04',
        rarity_perc: '7.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '50',
    name: 'Maximus',
    stars: '2.26',
    rank: '8',
    score: '113.61',
    image: '/assets/nfts/50.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Mech War',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Ice',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Lightsabers',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '7 Eyes',
        value: 'Nuke',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '51',
    name: 'Maximus',
    stars: '1.32',
    rank: '182',
    score: '56.11',
    image: '/assets/nfts/51.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Wind',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Forest',
        rarity: '8.33',
        rarity_perc: '12.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Bfg',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
      {
        trait_type: '7 Eyes',
        value: 'Doge',
        rarity: '12.50',
        rarity_perc: '8.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '52',
    name: 'Maximus',
    stars: '1.90',
    rank: '35',
    score: '91.8',
    image: '/assets/nfts/52.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Future',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Camo',
        rarity: '11.11',
        rarity_perc: '9.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Futu Rifle',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
      {
        trait_type: '7 Eyes',
        value: 'Laser Eyes',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '53',
    name: 'Maximus',
    stars: '1.31',
    rank: '190',
    score: '55.49',
    image: '/assets/nfts/53.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Gloom',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Epic',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Ice',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Laser Beams',
        rarity: '14.29',
        rarity_perc: '7.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '54',
    name: 'Maximus',
    stars: '1.07',
    rank: '286',
    score: '41.010000000000005',
    image: '/assets/nfts/54.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Gloom',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Meteor',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Frosty',
        rarity: '6.82',
        rarity_perc: '14.67',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Shotgun',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '55',
    name: 'Maximus',
    stars: '1.35',
    rank: '165',
    score: '58.25',
    image: '/assets/nfts/55.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Mist',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Camo',
        rarity: '6.98',
        rarity_perc: '14.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Desert',
        rarity: '8.11',
        rarity_perc: '12.33',
      },
      {
        trait_type: '6 Weapons',
        value: 'Shotgun',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '56',
    name: 'Maximus',
    stars: '1.64',
    rank: '104',
    score: '75.38',
    image: '/assets/nfts/56.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Toxic',
        rarity: '17.65',
        rarity_perc: '5.67',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 555',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Frosty',
        rarity: '7.50',
        rarity_perc: '13.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Frosty',
        rarity: '6.82',
        rarity_perc: '14.67',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Sniper',
        rarity: '23.08',
        rarity_perc: '4.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '57',
    name: 'Maximus',
    stars: '1.33',
    rank: '177',
    score: '56.96',
    image: '/assets/nfts/57.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Wind',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Camo',
        rarity: '6.98',
        rarity_perc: '14.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Frosty',
        rarity: '6.82',
        rarity_perc: '14.67',
      },
      {
        trait_type: '6 Weapons',
        value: 'Shotgun',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '58',
    name: 'Maximus',
    stars: '1.79',
    rank: '64',
    score: '84.95',
    image: '/assets/nfts/58.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Factory',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Desert',
        rarity: '8.11',
        rarity_perc: '12.33',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Plasma',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
      {
        trait_type: '7 Eyes',
        value: 'Doge',
        rarity: '12.50',
        rarity_perc: '8.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '59',
    name: 'Maximus',
    stars: '1.47',
    rank: '141',
    score: '65.28999999999999',
    image: '/assets/nfts/59.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Wind',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Neon',
        rarity: '9.09',
        rarity_perc: '11.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Assault',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
      {
        trait_type: '7 Eyes',
        value: 'Doge',
        rarity: '12.50',
        rarity_perc: '8.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '60',
    name: 'Maximus',
    stars: '1.99',
    rank: '24',
    score: '97.14',
    image: '/assets/nfts/60.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Battlefront',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Spacy',
        rarity: '7.14',
        rarity_perc: '14.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Camo',
        rarity: '11.11',
        rarity_perc: '9.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Bfg',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
      {
        trait_type: '7 Eyes',
        value: 'Rocket',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '61',
    name: 'Maximus',
    stars: '1.32',
    rank: '183',
    score: '56.05',
    image: '/assets/nfts/61.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Toxic',
        rarity: '17.65',
        rarity_perc: '5.67',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Spacy',
        rarity: '7.14',
        rarity_perc: '14.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Ice',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Toxic Blaster',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '62',
    name: 'Maximus',
    stars: '1.12',
    rank: '269',
    score: '44.19',
    image: '/assets/nfts/62.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Wind',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Meteor',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Paint',
        rarity: '10.00',
        rarity_perc: '10.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Shotgun',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '63',
    name: 'Maximus',
    stars: '1.17',
    rank: '247',
    score: '46.760000000000005',
    image: '/assets/nfts/63.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Clouds',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Ice',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Laser Eyes',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '64',
    name: 'Maximus',
    stars: '1.17',
    rank: '245',
    score: '46.87',
    image: '/assets/nfts/64.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Mist',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Frosty',
        rarity: '7.50',
        rarity_perc: '13.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Desert',
        rarity: '8.11',
        rarity_perc: '12.33',
      },
      {
        trait_type: '6 Weapons',
        value: 'Oldtimer',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '65',
    name: 'Maximus',
    stars: '1.73',
    rank: '79',
    score: '81.15',
    image: '/assets/nfts/65.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Mech War',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Desert',
        rarity: '8.11',
        rarity_perc: '12.33',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Moneybag',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '66',
    name: 'Maximus',
    stars: '0.85',
    rank: '299',
    score: '27.31',
    image: '/assets/nfts/66.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Mist',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Forest',
        rarity: '8.33',
        rarity_perc: '12.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '67',
    name: 'Maximus',
    stars: '1.86',
    rank: '48',
    score: '89.15',
    image: '/assets/nfts/67.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Battlefront',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Camo',
        rarity: '11.11',
        rarity_perc: '9.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Nuke',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '68',
    name: 'Maximus',
    stars: '1.18',
    rank: '233',
    score: '47.53',
    image: '/assets/nfts/68.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Clouds',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Spacy',
        rarity: '7.14',
        rarity_perc: '14.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bubble Gun',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '69',
    name: 'Maximus',
    stars: '1.99',
    rank: '23',
    score: '97.27',
    image: '/assets/nfts/69.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Fire',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 38',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Frosty',
        rarity: '7.50',
        rarity_perc: '13.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Camo',
        rarity: '11.11',
        rarity_perc: '9.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Shotgun',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '70',
    name: 'Maximus',
    stars: '1.78',
    rank: '68',
    score: '84.38',
    image: '/assets/nfts/70.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Sparks',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 909',
        rarity: '27.27',
        rarity_perc: '3.67',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Meteor',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Neon',
        rarity: '9.09',
        rarity_perc: '11.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Shotgun',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '71',
    name: 'Maximus',
    stars: '1.55',
    rank: '122',
    score: '69.87',
    image: '/assets/nfts/71.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Aurora',
        rarity: '15.00',
        rarity_perc: '6.67',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 555',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Neon',
        rarity: '9.09',
        rarity_perc: '11.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Bfg',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '72',
    name: 'Maximus',
    stars: '1.70',
    rank: '84',
    score: '79.55',
    image: '/assets/nfts/72.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Mist',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Ice',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Doge',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '7 Eyes',
        value: 'Moneybag',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '73',
    name: 'Maximus',
    stars: '1.23',
    rank: '215',
    score: '50.900000000000006',
    image: '/assets/nfts/73.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Wind',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Camo',
        rarity: '6.98',
        rarity_perc: '14.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Neon',
        rarity: '9.09',
        rarity_perc: '11.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bubble Gun',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '74',
    name: 'Maximus',
    stars: '1.16',
    rank: '251',
    score: '46.45',
    image: '/assets/nfts/74.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Gloom',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Frosty',
        rarity: '7.50',
        rarity_perc: '13.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Ice',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Toxic Blaster',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '75',
    name: 'Maximus',
    stars: '1.45',
    rank: '145',
    score: '64.02',
    image: '/assets/nfts/75.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Clouds',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Frosty',
        rarity: '7.50',
        rarity_perc: '13.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Ice',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Sniper',
        rarity: '23.08',
        rarity_perc: '4.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Doge',
        rarity: '12.50',
        rarity_perc: '8.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '76',
    name: 'Maximus',
    stars: '1.12',
    rank: '270',
    score: '44.0',
    image: '/assets/nfts/76.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Clouds',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Frosty',
        rarity: '7.50',
        rarity_perc: '13.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Paint',
        rarity: '10.00',
        rarity_perc: '10.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Shotgun',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '77',
    name: 'Maximus',
    stars: '1.11',
    rank: '276',
    score: '43.230000000000004',
    image: '/assets/nfts/77.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Gloom',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Forest',
        rarity: '8.33',
        rarity_perc: '12.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Doge',
        rarity: '12.50',
        rarity_perc: '8.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '78',
    name: 'Maximus',
    stars: '1.15',
    rank: '255',
    score: '45.85',
    image: '/assets/nfts/78.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Mist',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Meteor',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Forest',
        rarity: '8.33',
        rarity_perc: '12.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Plasma',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '79',
    name: 'Maximus',
    stars: '1.27',
    rank: '201',
    score: '53.39',
    image: '/assets/nfts/79.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Clouds',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Desert',
        rarity: '8.11',
        rarity_perc: '12.33',
      },
      {
        trait_type: '6 Weapons',
        value: 'Electro Gun',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '80',
    name: 'Maximus',
    stars: '1.69',
    rank: '89',
    score: '78.71000000000001',
    image: '/assets/nfts/80.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Factory',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Neon',
        rarity: '9.09',
        rarity_perc: '11.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Moneybag',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '81',
    name: 'Maximus',
    stars: '1.14',
    rank: '262',
    score: '45.04',
    image: '/assets/nfts/81.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Gloom',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Forest',
        rarity: '8.33',
        rarity_perc: '12.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Futu Rifle',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '82',
    name: 'Maximus',
    stars: '0.90',
    rank: '298',
    score: '30.73',
    image: '/assets/nfts/82.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Mist',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Forest',
        rarity: '8.33',
        rarity_perc: '12.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '83',
    name: 'Maximus',
    stars: '1.35',
    rank: '171',
    score: '57.67',
    image: '/assets/nfts/83.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Wind',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Meteor',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Frosty',
        rarity: '6.82',
        rarity_perc: '14.67',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Assault',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '84',
    name: 'Maximus',
    stars: '1.17',
    rank: '248',
    score: '46.73',
    image: '/assets/nfts/84.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Gloom',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Spacy',
        rarity: '7.14',
        rarity_perc: '14.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Forest',
        rarity: '8.33',
        rarity_perc: '12.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Futu Rifle',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '85',
    name: 'Maximus',
    stars: '1.25',
    rank: '213',
    score: '51.81',
    image: '/assets/nfts/85.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Wind',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Desert',
        rarity: '8.11',
        rarity_perc: '12.33',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bubble Gun',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '86',
    name: 'Maximus',
    stars: '1.29',
    rank: '195',
    score: '54.22',
    image: '/assets/nfts/86.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Mist',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Frosty',
        rarity: '6.82',
        rarity_perc: '14.67',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Moneybag',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '87',
    name: 'Maximus',
    stars: '1.71',
    rank: '83',
    score: '80.16',
    image: '/assets/nfts/87.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Fire',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 38',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Spacy',
        rarity: '7.14',
        rarity_perc: '14.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Ice',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Sniper',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '88',
    name: 'Maximus',
    stars: '2.17',
    rank: '13',
    score: '108.19999999999999',
    image: '/assets/nfts/88.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Fire',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 809',
        rarity: '42.86',
        rarity_perc: '2.33',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Spacy',
        rarity: '7.14',
        rarity_perc: '14.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Desert',
        rarity: '8.11',
        rarity_perc: '12.33',
      },
      {
        trait_type: '6 Weapons',
        value: 'Toxic Blaster',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '89',
    name: 'Maximus',
    stars: '1.49',
    rank: '135',
    score: '66.75999999999999',
    image: '/assets/nfts/89.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Wind',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Electro Gun',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Doge',
        rarity: '12.50',
        rarity_perc: '8.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '90',
    name: 'Maximus',
    stars: '1.33',
    rank: '178',
    score: '56.81',
    image: '/assets/nfts/90.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Gloom',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Desert',
        rarity: '8.11',
        rarity_perc: '12.33',
      },
      {
        trait_type: '6 Weapons',
        value: 'Electro Gun',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '91',
    name: 'Maximus',
    stars: '1.08',
    rank: '285',
    score: '41.69',
    image: '/assets/nfts/91.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Wind',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Frosty',
        rarity: '7.50',
        rarity_perc: '13.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Ice',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Shotgun',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '92',
    name: 'Maximus',
    stars: '1.41',
    rank: '151',
    score: '61.67',
    image: '/assets/nfts/92.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Clouds',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Camo',
        rarity: '6.98',
        rarity_perc: '14.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Desert',
        rarity: '8.11',
        rarity_perc: '12.33',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Assault',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '93',
    name: 'Maximus',
    stars: '1.65',
    rank: '97',
    score: '76.44',
    image: '/assets/nfts/93.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Battlefront',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Frosty',
        rarity: '6.82',
        rarity_perc: '14.67',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Moneybag',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '94',
    name: 'Maximus',
    stars: '1.47',
    rank: '142',
    score: '65.23',
    image: '/assets/nfts/94.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Mech War',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Desert',
        rarity: '8.11',
        rarity_perc: '12.33',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Doge',
        rarity: '12.50',
        rarity_perc: '8.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '95',
    name: 'Maximus',
    stars: '1.90',
    rank: '37',
    score: '91.68',
    image: '/assets/nfts/95.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Battlefront',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Neon',
        rarity: '9.09',
        rarity_perc: '11.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Assault',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
      {
        trait_type: '7 Eyes',
        value: 'Laser Eyes',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '96',
    name: 'Maximus',
    stars: '1.49',
    rank: '137',
    score: '66.57',
    image: '/assets/nfts/96.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Toxic',
        rarity: '17.65',
        rarity_perc: '5.67',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Meteor',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Neon',
        rarity: '9.09',
        rarity_perc: '11.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Shotgun',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '97',
    name: 'Maximus',
    stars: '1.11',
    rank: '278',
    score: '43.06',
    image: '/assets/nfts/97.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Wind',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Camo',
        rarity: '11.11',
        rarity_perc: '9.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Shotgun',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '98',
    name: 'Maximus',
    stars: '1.50',
    rank: '132',
    score: '67.19',
    image: '/assets/nfts/98.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Aurora',
        rarity: '15.00',
        rarity_perc: '6.67',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 38',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Frosty',
        rarity: '7.50',
        rarity_perc: '13.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Ice',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Shotgun',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '99',
    name: 'Maximus',
    stars: '0.98',
    rank: '297',
    score: '35.56999999999999',
    image: '/assets/nfts/99.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Gloom',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Spacy',
        rarity: '7.14',
        rarity_perc: '14.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Flamer',
        rarity: '13.04',
        rarity_perc: '7.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '100',
    name: 'Maximus',
    stars: '1.07',
    rank: '288',
    score: '40.84',
    image: '/assets/nfts/100.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Gloom',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Bfg',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '101',
    name: 'Maximus',
    stars: '1.85',
    rank: '57',
    score: '88.19000000000001',
    image: '/assets/nfts/101.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Toxic',
        rarity: '17.65',
        rarity_perc: '5.67',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 880',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Camo',
        rarity: '6.98',
        rarity_perc: '14.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Frosty',
        rarity: '6.82',
        rarity_perc: '14.67',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Sniper',
        rarity: '23.08',
        rarity_perc: '4.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '102',
    name: 'Maximus',
    stars: '1.30',
    rank: '192',
    score: '54.620000000000005',
    image: '/assets/nfts/102.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Mist',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Epic',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Frosty',
        rarity: '6.82',
        rarity_perc: '14.67',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Laser Beams',
        rarity: '14.29',
        rarity_perc: '7.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '103',
    name: 'Maximus',
    stars: '1.65',
    rank: '98',
    score: '76.32',
    image: '/assets/nfts/103.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Clouds',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Paint',
        rarity: '10.00',
        rarity_perc: '10.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Shotgun',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '7 Eyes',
        value: 'Laser Beams',
        rarity: '14.29',
        rarity_perc: '7.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '104',
    name: 'Maximus',
    stars: '1.32',
    rank: '181',
    score: '56.13',
    image: '/assets/nfts/104.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Gloom',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Epic',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Forest',
        rarity: '8.33',
        rarity_perc: '12.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Laser Beams',
        rarity: '14.29',
        rarity_perc: '7.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '105',
    name: 'Maximus',
    stars: '1.89',
    rank: '39',
    score: '90.88',
    image: '/assets/nfts/105.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Future',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Spacy',
        rarity: '7.14',
        rarity_perc: '14.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Assault',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
      {
        trait_type: '7 Eyes',
        value: 'Laser Beams',
        rarity: '14.29',
        rarity_perc: '7.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '106',
    name: 'Maximus',
    stars: '1.12',
    rank: '271',
    score: '43.8',
    image: '/assets/nfts/106.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Gloom',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Camo',
        rarity: '6.98',
        rarity_perc: '14.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Toxic Blaster',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '107',
    name: 'Maximus',
    stars: '1.31',
    rank: '188',
    score: '55.730000000000004',
    image: '/assets/nfts/107.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Mech War 2',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Camo',
        rarity: '11.11',
        rarity_perc: '9.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '108',
    name: 'Maximus',
    stars: '1.14',
    rank: '263',
    score: '44.92',
    image: '/assets/nfts/108.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Wind',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Camo',
        rarity: '6.98',
        rarity_perc: '14.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Desert',
        rarity: '8.11',
        rarity_perc: '12.33',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Plasma',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '109',
    name: 'Maximus',
    stars: '1.45',
    rank: '146',
    score: '63.940000000000005',
    image: '/assets/nfts/109.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Factory',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Frosty',
        rarity: '6.82',
        rarity_perc: '14.67',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Doge',
        rarity: '12.50',
        rarity_perc: '8.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '110',
    name: 'Maximus',
    stars: '1.21',
    rank: '223',
    score: '49.15',
    image: '/assets/nfts/110.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Gloom',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Forest',
        rarity: '8.33',
        rarity_perc: '12.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Flamer',
        rarity: '13.04',
        rarity_perc: '7.67',
      },
      {
        trait_type: '7 Eyes',
        value: 'Doge',
        rarity: '12.50',
        rarity_perc: '8.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '111',
    name: 'Maximus',
    stars: '1.50',
    rank: '131',
    score: '67.27',
    image: '/assets/nfts/111.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Sparks',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Frosty',
        rarity: '7.50',
        rarity_perc: '13.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Camo',
        rarity: '11.11',
        rarity_perc: '9.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Assault',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '112',
    name: 'Maximus',
    stars: '1.05',
    rank: '290',
    score: '39.56',
    image: '/assets/nfts/112.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Gloom',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Frosty',
        rarity: '7.50',
        rarity_perc: '13.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Shotgun',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '113',
    name: 'Maximus',
    stars: '1.45',
    rank: '148',
    score: '63.85',
    image: '/assets/nfts/113.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Toxic',
        rarity: '17.65',
        rarity_perc: '5.67',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Camo',
        rarity: '6.98',
        rarity_perc: '14.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Shotgun',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '114',
    name: 'Maximus',
    stars: '1.98',
    rank: '25',
    score: '96.58',
    image: '/assets/nfts/114.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Nuke',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Meteor',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Paint',
        rarity: '10.00',
        rarity_perc: '10.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Sniper',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
      {
        trait_type: '7 Eyes',
        value: 'Rocket',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '115',
    name: 'Maximus',
    stars: '1.63',
    rank: '105',
    score: '75.32',
    image: '/assets/nfts/115.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Showroom',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 38',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Desert',
        rarity: '8.11',
        rarity_perc: '12.33',
      },
      {
        trait_type: '6 Weapons',
        value: 'Futu Rifle',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '116',
    name: 'Maximus',
    stars: '1.34',
    rank: '172',
    score: '57.41',
    image: '/assets/nfts/116.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Toxic',
        rarity: '17.65',
        rarity_perc: '5.67',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Camo',
        rarity: '6.98',
        rarity_perc: '14.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Ice',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Futu Rifle',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '117',
    name: 'Maximus',
    stars: '1.74',
    rank: '75',
    score: '81.53',
    image: '/assets/nfts/117.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Factory',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Camo',
        rarity: '6.98',
        rarity_perc: '14.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Forest',
        rarity: '8.33',
        rarity_perc: '12.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Flamer',
        rarity: '13.04',
        rarity_perc: '7.67',
      },
      {
        trait_type: '7 Eyes',
        value: 'Laser Beams',
        rarity: '14.29',
        rarity_perc: '7.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '118',
    name: 'Maximus',
    stars: '2.19',
    rank: '12',
    score: '109.02',
    image: '/assets/nfts/118.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Gold',
        rarity: '27.27',
        rarity_perc: '3.67',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 880',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Camo',
        rarity: '6.98',
        rarity_perc: '14.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Camo',
        rarity: '11.11',
        rarity_perc: '9.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Railgun',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '119',
    name: 'Maximus',
    stars: '1.68',
    rank: '90',
    score: '78.35',
    image: '/assets/nfts/119.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Showroom',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 555',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Frosty',
        rarity: '7.50',
        rarity_perc: '13.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Neon',
        rarity: '9.09',
        rarity_perc: '11.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Carbine',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '120',
    name: 'Maximus',
    stars: '1.30',
    rank: '192',
    score: '54.620000000000005',
    image: '/assets/nfts/120.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Mech War',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Paint',
        rarity: '10.00',
        rarity_perc: '10.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '121',
    name: 'Maximus',
    stars: '1.13',
    rank: '266',
    score: '44.51',
    image: '/assets/nfts/121.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Mist',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Meteor',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Oldtimer',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '122',
    name: 'Maximus',
    stars: '2.06',
    rank: '19',
    score: '101.03999999999999',
    image: '/assets/nfts/122.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Aurora',
        rarity: '15.00',
        rarity_perc: '6.67',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 809',
        rarity: '42.86',
        rarity_perc: '2.33',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Camo',
        rarity: '6.98',
        rarity_perc: '14.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Camo',
        rarity: '11.11',
        rarity_perc: '9.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Oldtimer',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '123',
    name: 'Maximus',
    stars: '1.40',
    rank: '152',
    score: '61.31',
    image: '/assets/nfts/123.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Mist',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Ice',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Plasma',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
      {
        trait_type: '7 Eyes',
        value: 'Laser Eyes',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '124',
    name: 'Maximus',
    stars: '1.56',
    rank: '119',
    score: '71.08',
    image: '/assets/nfts/124.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Aurora',
        rarity: '15.00',
        rarity_perc: '6.67',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 555',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Frosty',
        rarity: '7.50',
        rarity_perc: '13.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Frosty',
        rarity: '6.82',
        rarity_perc: '14.67',
      },
      {
        trait_type: '6 Weapons',
        value: 'Futu Rifle',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '125',
    name: 'Maximus',
    stars: '1.96',
    rank: '28',
    score: '94.9',
    image: '/assets/nfts/125.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Battlefront',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Desert',
        rarity: '8.11',
        rarity_perc: '12.33',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Assault',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '7 Eyes',
        value: 'Laser Beams',
        rarity: '14.29',
        rarity_perc: '7.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '126',
    name: 'Maximus',
    stars: '1.15',
    rank: '259',
    score: '45.629999999999995',
    image: '/assets/nfts/126.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Mist',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Meteor',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Desert',
        rarity: '8.11',
        rarity_perc: '12.33',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Bfg',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '127',
    name: 'Maximus',
    stars: '1.15',
    rank: '254',
    score: '45.89',
    image: '/assets/nfts/127.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Wind',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Frosty',
        rarity: '6.82',
        rarity_perc: '14.67',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Laser Eyes',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '128',
    name: 'Maximus',
    stars: '2.35',
    rank: '7',
    score: '119.08',
    image: '/assets/nfts/128.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Clouds',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Legendary',
        rarity: '50.00',
        rarity_perc: '2.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Ice',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Doge',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '7 Eyes',
        value: 'Laser Eyes',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '129',
    name: 'Maximus',
    stars: '1.69',
    rank: '88',
    score: '78.81',
    image: '/assets/nfts/129.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Toxic',
        rarity: '17.65',
        rarity_perc: '5.67',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 38',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Frosty',
        rarity: '7.50',
        rarity_perc: '13.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Forest',
        rarity: '8.33',
        rarity_perc: '12.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bubble Gun',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '130',
    name: 'Maximus',
    stars: '1.83',
    rank: '60',
    score: '87.52',
    image: '/assets/nfts/130.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Showroom',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 009',
        rarity: '27.27',
        rarity_perc: '3.67',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Frosty',
        rarity: '7.50',
        rarity_perc: '13.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Neon',
        rarity: '9.09',
        rarity_perc: '11.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Plasma',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '131',
    name: 'Maximus',
    stars: '1.50',
    rank: '130',
    score: '67.36000000000001',
    image: '/assets/nfts/131.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Nuke',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Frosty',
        rarity: '6.82',
        rarity_perc: '14.67',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Doge',
        rarity: '12.50',
        rarity_perc: '8.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '132',
    name: 'Maximus',
    stars: '1.04',
    rank: '291',
    score: '38.77',
    image: '/assets/nfts/132.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Wind',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Frosty',
        rarity: '6.82',
        rarity_perc: '14.67',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Shotgun',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '133',
    name: 'Maximus',
    stars: '1.35',
    rank: '169',
    score: '57.7',
    image: '/assets/nfts/133.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Mist',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Neon',
        rarity: '9.09',
        rarity_perc: '11.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Assault',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '134',
    name: 'Maximus',
    stars: '1.27',
    rank: '204',
    score: '53.11',
    image: '/assets/nfts/134.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Aurora',
        rarity: '15.00',
        rarity_perc: '6.67',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Neon',
        rarity: '9.09',
        rarity_perc: '11.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Oldtimer',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '135',
    name: 'Maximus',
    stars: '1.18',
    rank: '233',
    score: '47.53',
    image: '/assets/nfts/135.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Mist',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Spacy',
        rarity: '7.14',
        rarity_perc: '14.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Assault',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '136',
    name: 'Maximus',
    stars: '1.18',
    rank: '228',
    score: '47.89',
    image: '/assets/nfts/136.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Gloom',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Frosty',
        rarity: '7.50',
        rarity_perc: '13.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bubble Gun',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '137',
    name: 'Maximus',
    stars: '2.22',
    rank: '11',
    score: '110.83',
    image: '/assets/nfts/137.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Nuke',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Forest',
        rarity: '8.33',
        rarity_perc: '12.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Lightsabers',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '7 Eyes',
        value: 'Nuke',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '138',
    name: 'Maximus',
    stars: '1.09',
    rank: '283',
    score: '42.33',
    image: '/assets/nfts/138.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Clouds',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Frosty',
        rarity: '7.50',
        rarity_perc: '13.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Forest',
        rarity: '8.33',
        rarity_perc: '12.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Shotgun',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '139',
    name: 'Maximus',
    stars: '1.18',
    rank: '235',
    score: '47.519999999999996',
    image: '/assets/nfts/139.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Clouds',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Meteor',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Paint',
        rarity: '10.00',
        rarity_perc: '10.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Plasma',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '140',
    name: 'Maximus',
    stars: '1.21',
    rank: '222',
    score: '49.22',
    image: '/assets/nfts/140.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Mist',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Frosty',
        rarity: '6.82',
        rarity_perc: '14.67',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Rocket',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '141',
    name: 'Maximus',
    stars: '1.50',
    rank: '134',
    score: '66.84',
    image: '/assets/nfts/141.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Mech War',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Epic',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Ice',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '142',
    name: 'Maximus',
    stars: '1.96',
    rank: '27',
    score: '95.44',
    image: '/assets/nfts/142.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Showroom',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 909',
        rarity: '27.27',
        rarity_perc: '3.67',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Meteor',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Frosty',
        rarity: '6.82',
        rarity_perc: '14.67',
      },
      {
        trait_type: '6 Weapons',
        value: 'Electro Gun',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '143',
    name: 'Maximus',
    stars: '1.19',
    rank: '227',
    score: '48.04',
    image: '/assets/nfts/143.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Gloom',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Meteor',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Neon',
        rarity: '9.09',
        rarity_perc: '11.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Toxic Blaster',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '144',
    name: 'Maximus',
    stars: '1.34',
    rank: '175',
    score: '57.09',
    image: '/assets/nfts/144.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Wind',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Desert',
        rarity: '8.11',
        rarity_perc: '12.33',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Nuke',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '145',
    name: 'Maximus',
    stars: '1.17',
    rank: '245',
    score: '46.87',
    image: '/assets/nfts/145.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Clouds',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Frosty',
        rarity: '7.50',
        rarity_perc: '13.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Desert',
        rarity: '8.11',
        rarity_perc: '12.33',
      },
      {
        trait_type: '6 Weapons',
        value: 'Futu Rifle',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '146',
    name: 'Maximus',
    stars: '1.55',
    rank: '121',
    score: '69.89',
    image: '/assets/nfts/146.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Wind',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Camo',
        rarity: '6.98',
        rarity_perc: '14.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Paint',
        rarity: '10.00',
        rarity_perc: '10.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Sniper',
        rarity: '23.08',
        rarity_perc: '4.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Rocket',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '147',
    name: 'Maximus',
    stars: '2.83',
    rank: '1',
    score: '148.05',
    image: '/assets/nfts/147.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Future',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Legendary',
        rarity: '50.00',
        rarity_perc: '2.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Ice',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Lightsabers',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '7 Eyes',
        value: 'Rocket',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '148',
    name: 'Maximus',
    stars: '1.26',
    rank: '210',
    score: '52.519999999999996',
    image: '/assets/nfts/148.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Mist',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Meteor',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Paint',
        rarity: '10.00',
        rarity_perc: '10.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bubble Gun',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '149',
    name: 'Maximus',
    stars: '1.32',
    rank: '183',
    score: '56.05',
    image: '/assets/nfts/149.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Clouds',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Spacy',
        rarity: '7.14',
        rarity_perc: '14.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Forest',
        rarity: '8.33',
        rarity_perc: '12.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Flamer',
        rarity: '13.04',
        rarity_perc: '7.67',
      },
      {
        trait_type: '7 Eyes',
        value: 'Laser Beams',
        rarity: '14.29',
        rarity_perc: '7.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '150',
    name: 'Maximus',
    stars: '1.39',
    rank: '158',
    score: '60.629999999999995',
    image: '/assets/nfts/150.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Wind',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Spacy',
        rarity: '7.14',
        rarity_perc: '14.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Carbine',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
      {
        trait_type: '7 Eyes',
        value: 'Laser Eyes',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '151',
    name: 'Maximus',
    stars: '1.52',
    rank: '127',
    score: '68.21000000000001',
    image: '/assets/nfts/151.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Mist',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Epic',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Frosty',
        rarity: '6.82',
        rarity_perc: '14.67',
      },
      {
        trait_type: '6 Weapons',
        value: 'Lightsabers',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '152',
    name: 'Maximus',
    stars: '1.57',
    rank: '118',
    score: '71.54',
    image: '/assets/nfts/152.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Toxic',
        rarity: '17.65',
        rarity_perc: '5.67',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 38',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Desert',
        rarity: '8.11',
        rarity_perc: '12.33',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Bfg',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '153',
    name: 'Maximus',
    stars: '1.65',
    rank: '100',
    score: '76.01999999999998',
    image: '/assets/nfts/153.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Gold',
        rarity: '27.27',
        rarity_perc: '3.67',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 555',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Meteor',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Ice',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Flamer',
        rarity: '13.04',
        rarity_perc: '7.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '154',
    name: 'Maximus',
    stars: '1.21',
    rank: '220',
    score: '49.54',
    image: '/assets/nfts/154.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Gloom',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Moneybag',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '155',
    name: 'Maximus',
    stars: '1.89',
    rank: '40',
    score: '90.78999999999999',
    image: '/assets/nfts/155.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Sparks',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 009',
        rarity: '27.27',
        rarity_perc: '3.67',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Meteor',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Neon',
        rarity: '9.09',
        rarity_perc: '11.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Sniper',
        rarity: '23.08',
        rarity_perc: '4.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '156',
    name: 'Maximus',
    stars: '1.33',
    rank: '176',
    score: '56.97',
    image: '/assets/nfts/156.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Gloom',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Spacy',
        rarity: '7.14',
        rarity_perc: '14.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Paint',
        rarity: '10.00',
        rarity_perc: '10.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Electro Gun',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '157',
    name: 'Maximus',
    stars: '1.88',
    rank: '44',
    score: '90.03999999999999',
    image: '/assets/nfts/157.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Gold',
        rarity: '27.27',
        rarity_perc: '3.67',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 909',
        rarity: '27.27',
        rarity_perc: '3.67',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Meteor',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Camo',
        rarity: '11.11',
        rarity_perc: '9.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Flamer',
        rarity: '13.04',
        rarity_perc: '7.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '158',
    name: 'Maximus',
    stars: '1.79',
    rank: '66',
    score: '84.58',
    image: '/assets/nfts/158.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Gold',
        rarity: '27.27',
        rarity_perc: '3.67',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 38',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Camo',
        rarity: '6.98',
        rarity_perc: '14.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Paint',
        rarity: '10.00',
        rarity_perc: '10.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Plasma',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '159',
    name: 'Maximus',
    stars: '1.40',
    rank: '157',
    score: '60.79',
    image: '/assets/nfts/159.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Clouds',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Meteor',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Sniper',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
      {
        trait_type: '7 Eyes',
        value: 'Laser Beams',
        rarity: '14.29',
        rarity_perc: '7.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '160',
    name: 'Maximus',
    stars: '1.49',
    rank: '136',
    score: '66.67',
    image: '/assets/nfts/160.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Mist',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Assault',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '7 Eyes',
        value: 'Doge',
        rarity: '12.50',
        rarity_perc: '8.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '161',
    name: 'Maximus',
    stars: '1.36',
    rank: '163',
    score: '58.77',
    image: '/assets/nfts/161.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Gloom',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Frosty',
        rarity: '6.82',
        rarity_perc: '14.67',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Shotgun',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '7 Eyes',
        value: 'Rocket',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '162',
    name: 'Maximus',
    stars: '1.07',
    rank: '287',
    score: '40.96',
    image: '/assets/nfts/162.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Wind',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Ice',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Laser Beams',
        rarity: '14.29',
        rarity_perc: '7.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '163',
    name: 'Maximus',
    stars: '1.38',
    rank: '160',
    score: '59.72',
    image: '/assets/nfts/163.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Gloom',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Camo',
        rarity: '11.11',
        rarity_perc: '9.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Shotgun',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '164',
    name: 'Maximus',
    stars: '1.66',
    rank: '95',
    score: '76.92',
    image: '/assets/nfts/164.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Sparks',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 38',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Frosty',
        rarity: '7.50',
        rarity_perc: '13.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Neon',
        rarity: '9.09',
        rarity_perc: '11.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Sniper',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '165',
    name: 'Maximus',
    stars: '1.32',
    rank: '180',
    score: '56.39',
    image: '/assets/nfts/165.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Clouds',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Camo',
        rarity: '11.11',
        rarity_perc: '9.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Shotgun',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '166',
    name: 'Maximus',
    stars: '1.35',
    rank: '168',
    score: '57.72',
    image: '/assets/nfts/166.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Wind',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Spacy',
        rarity: '7.14',
        rarity_perc: '14.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Paint',
        rarity: '10.00',
        rarity_perc: '10.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Flamer',
        rarity: '13.04',
        rarity_perc: '7.67',
      },
      {
        trait_type: '7 Eyes',
        value: 'Laser Beams',
        rarity: '14.29',
        rarity_perc: '7.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '167',
    name: 'Maximus',
    stars: '1.86',
    rank: '49',
    score: '89.11',
    image: '/assets/nfts/167.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Fire',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 555',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Forest',
        rarity: '8.33',
        rarity_perc: '12.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Shotgun',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '168',
    name: 'Maximus',
    stars: '1.54',
    rank: '123',
    score: '69.46000000000001',
    image: '/assets/nfts/168.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Aurora',
        rarity: '15.00',
        rarity_perc: '6.67',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 555',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Spacy',
        rarity: '7.14',
        rarity_perc: '14.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Oldtimer',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '169',
    name: 'Maximus',
    stars: '1.74',
    rank: '74',
    score: '81.59',
    image: '/assets/nfts/169.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Fire',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 38',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Spacy',
        rarity: '7.14',
        rarity_perc: '14.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Ice',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Carbine',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '170',
    name: 'Maximus',
    stars: '1.26',
    rank: '211',
    score: '52.370000000000005',
    image: '/assets/nfts/170.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Mist',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Camo',
        rarity: '6.98',
        rarity_perc: '14.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Electro Gun',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '171',
    name: 'Maximus',
    stars: '2.17',
    rank: '14',
    score: '107.75',
    image: '/assets/nfts/171.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Gold',
        rarity: '27.27',
        rarity_perc: '3.67',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 809',
        rarity: '42.86',
        rarity_perc: '2.33',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Spacy',
        rarity: '7.14',
        rarity_perc: '14.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Frosty',
        rarity: '6.82',
        rarity_perc: '14.67',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Bfg',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '172',
    name: 'Maximus',
    stars: '1.63',
    rank: '106',
    score: '75.18',
    image: '/assets/nfts/172.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Future',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Moneybag',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '173',
    name: 'Maximus',
    stars: '1.26',
    rank: '208',
    score: '52.53',
    image: '/assets/nfts/173.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Mist',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Spacy',
        rarity: '7.14',
        rarity_perc: '14.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Railgun',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '174',
    name: 'Maximus',
    stars: '1.29',
    rank: '198',
    score: '54.17',
    image: '/assets/nfts/174.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Clouds',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Shotgun',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '175',
    name: 'Maximus',
    stars: '1.36',
    rank: '164',
    score: '58.510000000000005',
    image: '/assets/nfts/175.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Clouds',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Epic',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Forest',
        rarity: '8.33',
        rarity_perc: '12.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Laser Eyes',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '176',
    name: 'Maximus',
    stars: '1.40',
    rank: '154',
    score: '61.25',
    image: '/assets/nfts/176.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Wind',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Camo',
        rarity: '6.98',
        rarity_perc: '14.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Camo',
        rarity: '11.11',
        rarity_perc: '9.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Assault',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '177',
    name: 'Maximus',
    stars: '1.64',
    rank: '101',
    score: '75.82000000000001',
    image: '/assets/nfts/177.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Battlefront',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Camo',
        rarity: '11.11',
        rarity_perc: '9.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Laser Eyes',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '178',
    name: 'Maximus',
    stars: '1.49',
    rank: '138',
    score: '66.34',
    image: '/assets/nfts/178.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Mist',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Camo',
        rarity: '6.98',
        rarity_perc: '14.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Frosty',
        rarity: '6.82',
        rarity_perc: '14.67',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Assault',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
      {
        trait_type: '7 Eyes',
        value: 'Laser Beams',
        rarity: '14.29',
        rarity_perc: '7.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '179',
    name: 'Maximus',
    stars: '1.48',
    rank: '139',
    score: '65.91',
    image: '/assets/nfts/179.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Showroom',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 38',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Camo',
        rarity: '6.98',
        rarity_perc: '14.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Flamer',
        rarity: '13.04',
        rarity_perc: '7.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '180',
    name: 'Maximus',
    stars: '1.88',
    rank: '43',
    score: '90.48',
    image: '/assets/nfts/180.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Gloom',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Epic',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Neon',
        rarity: '9.09',
        rarity_perc: '11.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Doge',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '7 Eyes',
        value: 'Rocket',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '181',
    name: 'Maximus',
    stars: '1.12',
    rank: '273',
    score: '43.760000000000005',
    image: '/assets/nfts/181.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Clouds',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Desert',
        rarity: '8.11',
        rarity_perc: '12.33',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Laser Eyes',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '182',
    name: 'Maximus',
    stars: '1.85',
    rank: '56',
    score: '88.35000000000001',
    image: '/assets/nfts/182.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Future',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Neon',
        rarity: '9.09',
        rarity_perc: '11.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Plasma',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
      {
        trait_type: '7 Eyes',
        value: 'Laser Eyes',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '183',
    name: 'Maximus',
    stars: '1.15',
    rank: '259',
    score: '45.629999999999995',
    image: '/assets/nfts/183.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Clouds',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Meteor',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Desert',
        rarity: '8.11',
        rarity_perc: '12.33',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Bfg',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '184',
    name: 'Maximus',
    stars: '1.85',
    rank: '52',
    score: '88.61999999999999',
    image: '/assets/nfts/184.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Sparks',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 909',
        rarity: '27.27',
        rarity_perc: '3.67',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Meteor',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Paint',
        rarity: '10.00',
        rarity_perc: '10.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Sniper',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '185',
    name: 'Maximus',
    stars: '1.67',
    rank: '92',
    score: '77.23',
    image: '/assets/nfts/185.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Nuke',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Plasma',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
      {
        trait_type: '7 Eyes',
        value: 'Doge',
        rarity: '12.50',
        rarity_perc: '8.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '186',
    name: 'Maximus',
    stars: '1.85',
    rank: '54',
    score: '88.52',
    image: '/assets/nfts/186.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Sparks',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 009',
        rarity: '27.27',
        rarity_perc: '3.67',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Meteor',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Frosty',
        rarity: '6.82',
        rarity_perc: '14.67',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Sniper',
        rarity: '23.08',
        rarity_perc: '4.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '187',
    name: 'Maximus',
    stars: '1.11',
    rank: '275',
    score: '43.39',
    image: '/assets/nfts/187.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Wind',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Desert',
        rarity: '8.11',
        rarity_perc: '12.33',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Bfg',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '188',
    name: 'Maximus',
    stars: '1.11',
    rank: '277',
    score: '43.08',
    image: '/assets/nfts/188.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Wind',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Meteor',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Sniper',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '189',
    name: 'Maximus',
    stars: '1.77',
    rank: '70',
    score: '83.61',
    image: '/assets/nfts/189.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Factory',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Camo',
        rarity: '11.11',
        rarity_perc: '9.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Lightsabers',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '190',
    name: 'Maximus',
    stars: '1.18',
    rank: '230',
    score: '47.82',
    image: '/assets/nfts/190.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Clouds',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Camo',
        rarity: '11.11',
        rarity_perc: '9.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Carbine',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '191',
    name: 'Maximus',
    stars: '1.15',
    rank: '253',
    score: '46.06',
    image: '/assets/nfts/191.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Gloom',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Spacy',
        rarity: '7.14',
        rarity_perc: '14.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Neon',
        rarity: '9.09',
        rarity_perc: '11.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Bfg',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '192',
    name: 'Maximus',
    stars: '1.31',
    rank: '189',
    score: '55.51',
    image: '/assets/nfts/192.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Clouds',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Desert',
        rarity: '8.11',
        rarity_perc: '12.33',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Moneybag',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '193',
    name: 'Maximus',
    stars: '1.74',
    rank: '76',
    score: '81.47',
    image: '/assets/nfts/193.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Showroom',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 009',
        rarity: '27.27',
        rarity_perc: '3.67',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Frosty',
        rarity: '7.50',
        rarity_perc: '13.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Paint',
        rarity: '10.00',
        rarity_perc: '10.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Flamer',
        rarity: '13.04',
        rarity_perc: '7.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '194',
    name: 'Maximus',
    stars: '0.82',
    rank: '300',
    score: '25.8',
    image: '/assets/nfts/194.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Clouds',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Frosty',
        rarity: '6.82',
        rarity_perc: '14.67',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '195',
    name: 'Maximus',
    stars: '1.17',
    rank: '241',
    score: '47.1',
    image: '/assets/nfts/195.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Mist',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Frosty',
        rarity: '6.82',
        rarity_perc: '14.67',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Assault',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '196',
    name: 'Maximus',
    stars: '1.17',
    rank: '248',
    score: '46.73',
    image: '/assets/nfts/196.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Wind',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Spacy',
        rarity: '7.14',
        rarity_perc: '14.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Forest',
        rarity: '8.33',
        rarity_perc: '12.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Oldtimer',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '197',
    name: 'Maximus',
    stars: '1.18',
    rank: '229',
    score: '47.849999999999994',
    image: '/assets/nfts/197.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Clouds',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Frosty',
        rarity: '7.50',
        rarity_perc: '13.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Neon',
        rarity: '9.09',
        rarity_perc: '11.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Carbine',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '198',
    name: 'Maximus',
    stars: '1.58',
    rank: '117',
    score: '72.13',
    image: '/assets/nfts/198.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Mech War 2',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Desert',
        rarity: '8.11',
        rarity_perc: '12.33',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Carbine',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '199',
    name: 'Maximus',
    stars: '1.86',
    rank: '51',
    score: '88.85',
    image: '/assets/nfts/199.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Showroom',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 880',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Frosty',
        rarity: '7.50',
        rarity_perc: '13.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Ice',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Plasma',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '200',
    name: 'Maximus',
    stars: '1.00',
    rank: '295',
    score: '36.83',
    image: '/assets/nfts/200.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Gloom',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Spacy',
        rarity: '7.14',
        rarity_perc: '14.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Frosty',
        rarity: '6.82',
        rarity_perc: '14.67',
      },
      {
        trait_type: '6 Weapons',
        value: 'Flamer',
        rarity: '13.04',
        rarity_perc: '7.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '201',
    name: 'Maximus',
    stars: '1.22',
    rank: '218',
    score: '49.97',
    image: '/assets/nfts/201.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Toxic',
        rarity: '17.65',
        rarity_perc: '5.67',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Spacy',
        rarity: '7.14',
        rarity_perc: '14.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Paint',
        rarity: '10.00',
        rarity_perc: '10.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Flamer',
        rarity: '13.04',
        rarity_perc: '7.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '202',
    name: 'Maximus',
    stars: '1.10',
    rank: '280',
    score: '42.89',
    image: '/assets/nfts/202.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Gloom',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Frosty',
        rarity: '7.50',
        rarity_perc: '13.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Sniper',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '203',
    name: 'Maximus',
    stars: '1.32',
    rank: '185',
    score: '55.980000000000004',
    image: '/assets/nfts/203.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Gloom',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Camo',
        rarity: '6.98',
        rarity_perc: '14.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Paint',
        rarity: '10.00',
        rarity_perc: '10.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Shotgun',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '7 Eyes',
        value: 'Doge',
        rarity: '12.50',
        rarity_perc: '8.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '204',
    name: 'Maximus',
    stars: '1.59',
    rank: '113',
    score: '72.8',
    image: '/assets/nfts/204.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Gloom',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Spacy',
        rarity: '7.14',
        rarity_perc: '14.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Paint',
        rarity: '10.00',
        rarity_perc: '10.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Assault',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '7 Eyes',
        value: 'Doge',
        rarity: '12.50',
        rarity_perc: '8.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '205',
    name: 'Maximus',
    stars: '1.90',
    rank: '38',
    score: '91.36',
    image: '/assets/nfts/205.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Fire',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 880',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Spacy',
        rarity: '7.14',
        rarity_perc: '14.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Sniper',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '206',
    name: 'Maximus',
    stars: '1.48',
    rank: '140',
    score: '65.73',
    image: '/assets/nfts/206.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Nuke',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Frosty',
        rarity: '6.82',
        rarity_perc: '14.67',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Laser Beams',
        rarity: '14.29',
        rarity_perc: '7.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '207',
    name: 'Maximus',
    stars: '1.65',
    rank: '96',
    score: '76.56',
    image: '/assets/nfts/207.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Aurora',
        rarity: '15.00',
        rarity_perc: '6.67',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 555',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Spacy',
        rarity: '7.14',
        rarity_perc: '14.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Neon',
        rarity: '9.09',
        rarity_perc: '11.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Assault',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '208',
    name: 'Maximus',
    stars: '1.95',
    rank: '32',
    score: '94.61',
    image: '/assets/nfts/208.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Toxic',
        rarity: '17.65',
        rarity_perc: '5.67',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 009',
        rarity: '27.27',
        rarity_perc: '3.67',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Spacy',
        rarity: '7.14',
        rarity_perc: '14.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Shotgun',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '209',
    name: 'Maximus',
    stars: '1.17',
    rank: '250',
    score: '46.7',
    image: '/assets/nfts/209.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Gloom',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Meteor',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Neon',
        rarity: '9.09',
        rarity_perc: '11.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Shotgun',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '210',
    name: 'Maximus',
    stars: '1.72',
    rank: '82',
    score: '80.73',
    image: '/assets/nfts/210.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Nuke',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Camo',
        rarity: '11.11',
        rarity_perc: '9.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Moneybag',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '211',
    name: 'Maximus',
    stars: '1.16',
    rank: '252',
    score: '46.35',
    image: '/assets/nfts/211.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Mist',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Camo',
        rarity: '6.98',
        rarity_perc: '14.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Desert',
        rarity: '8.11',
        rarity_perc: '12.33',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Carbine',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '212',
    name: 'Maximus',
    stars: '1.84',
    rank: '59',
    score: '87.69999999999999',
    image: '/assets/nfts/212.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Toxic',
        rarity: '17.65',
        rarity_perc: '5.67',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 909',
        rarity: '27.27',
        rarity_perc: '3.67',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Meteor',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Paint',
        rarity: '10.00',
        rarity_perc: '10.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Toxic Blaster',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '213',
    name: 'Maximus',
    stars: '1.03',
    rank: '293',
    score: '38.18',
    image: '/assets/nfts/213.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Wind',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Camo',
        rarity: '6.98',
        rarity_perc: '14.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Forest',
        rarity: '8.33',
        rarity_perc: '12.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Flamer',
        rarity: '13.04',
        rarity_perc: '7.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '214',
    name: 'Maximus',
    stars: '1.02',
    rank: '294',
    score: '38.12',
    image: '/assets/nfts/214.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Wind',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Spacy',
        rarity: '7.14',
        rarity_perc: '14.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Desert',
        rarity: '8.11',
        rarity_perc: '12.33',
      },
      {
        trait_type: '6 Weapons',
        value: 'Flamer',
        rarity: '13.04',
        rarity_perc: '7.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '215',
    name: 'Maximus',
    stars: '1.27',
    rank: '202',
    score: '53.29',
    image: '/assets/nfts/215.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Clouds',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Frosty',
        rarity: '7.50',
        rarity_perc: '13.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Camo',
        rarity: '11.11',
        rarity_perc: '9.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Carbine',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '216',
    name: 'Maximus',
    stars: '2.01',
    rank: '21',
    score: '98.09',
    image: '/assets/nfts/216.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Toxic',
        rarity: '17.65',
        rarity_perc: '5.67',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 880',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Meteor',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Neon',
        rarity: '9.09',
        rarity_perc: '11.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Railgun',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '217',
    name: 'Maximus',
    stars: '1.59',
    rank: '114',
    score: '72.73',
    image: '/assets/nfts/217.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Mech War',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Desert',
        rarity: '8.11',
        rarity_perc: '12.33',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Rocket',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '218',
    name: 'Maximus',
    stars: '1.32',
    rank: '187',
    score: '55.85',
    image: '/assets/nfts/218.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Mist',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Meteor',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Forest',
        rarity: '8.33',
        rarity_perc: '12.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Electro Gun',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '219',
    name: 'Maximus',
    stars: '1.97',
    rank: '26',
    score: '95.65',
    image: '/assets/nfts/219.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Gold',
        rarity: '27.27',
        rarity_perc: '3.67',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 909',
        rarity: '27.27',
        rarity_perc: '3.67',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Meteor',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Forest',
        rarity: '8.33',
        rarity_perc: '12.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Futu Rifle',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '220',
    name: 'Maximus',
    stars: '1.66',
    rank: '94',
    score: '77.03',
    image: '/assets/nfts/220.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Sparks',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 009',
        rarity: '27.27',
        rarity_perc: '3.67',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Frosty',
        rarity: '7.50',
        rarity_perc: '13.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Flamer',
        rarity: '13.04',
        rarity_perc: '7.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '221',
    name: 'Maximus',
    stars: '1.23',
    rank: '216',
    score: '50.84',
    image: '/assets/nfts/221.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Mist',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Shotgun',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '222',
    name: 'Maximus',
    stars: '2.77',
    rank: '2',
    score: '144.72000000000003',
    image: '/assets/nfts/222.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Mech War 2',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Legendary',
        rarity: '50.00',
        rarity_perc: '2.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Ice',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Lightsabers',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '7 Eyes',
        value: 'Laser Eyes',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '223',
    name: 'Maximus',
    stars: '1.07',
    rank: '288',
    score: '40.84',
    image: '/assets/nfts/223.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Wind',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Sniper',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '224',
    name: 'Maximus',
    stars: '1.09',
    rank: '284',
    score: '42.25',
    image: '/assets/nfts/224.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Gloom',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Laser Beams',
        rarity: '14.29',
        rarity_perc: '7.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '225',
    name: 'Maximus',
    stars: '2.59',
    rank: '3',
    score: '133.59',
    image: '/assets/nfts/225.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Mech War 2',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Legendary',
        rarity: '50.00',
        rarity_perc: '2.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Camo',
        rarity: '11.11',
        rarity_perc: '9.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Nuke',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '226',
    name: 'Maximus',
    stars: '1.25',
    rank: '212',
    score: '52.1',
    image: '/assets/nfts/226.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Clouds',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Frosty',
        rarity: '6.82',
        rarity_perc: '14.67',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Shotgun',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '227',
    name: 'Maximus',
    stars: '1.21',
    rank: '221',
    score: '49.510000000000005',
    image: '/assets/nfts/227.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Wind',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Spacy',
        rarity: '7.14',
        rarity_perc: '14.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Ice',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Oldtimer',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '228',
    name: 'Maximus',
    stars: '1.17',
    rank: '241',
    score: '47.1',
    image: '/assets/nfts/228.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Gloom',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Frosty',
        rarity: '6.82',
        rarity_perc: '14.67',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bubble Gun',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '229',
    name: 'Maximus',
    stars: '1.18',
    rank: '231',
    score: '47.739999999999995',
    image: '/assets/nfts/229.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Wind',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Spacy',
        rarity: '7.14',
        rarity_perc: '14.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Ice',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Sniper',
        rarity: '23.08',
        rarity_perc: '4.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '230',
    name: 'Maximus',
    stars: '2.40',
    rank: '6',
    score: '122.03',
    image: '/assets/nfts/230.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Mech War 2',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Epic',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Paint',
        rarity: '10.00',
        rarity_perc: '10.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Lightsabers',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '7 Eyes',
        value: 'Moneybag',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '231',
    name: 'Maximus',
    stars: '1.73',
    rank: '80',
    score: '81.13999999999999',
    image: '/assets/nfts/231.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Aurora',
        rarity: '15.00',
        rarity_perc: '6.67',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 009',
        rarity: '27.27',
        rarity_perc: '3.67',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Forest',
        rarity: '8.33',
        rarity_perc: '12.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Oldtimer',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '232',
    name: 'Maximus',
    stars: '1.93',
    rank: '34',
    score: '93.16999999999999',
    image: '/assets/nfts/232.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Aurora',
        rarity: '15.00',
        rarity_perc: '6.67',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 809',
        rarity: '42.86',
        rarity_perc: '2.33',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Frosty',
        rarity: '7.50',
        rarity_perc: '13.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Camo',
        rarity: '11.11',
        rarity_perc: '9.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Flamer',
        rarity: '13.04',
        rarity_perc: '7.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '233',
    name: 'Maximus',
    stars: '1.40',
    rank: '153',
    score: '61.290000000000006',
    image: '/assets/nfts/233.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Clouds',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Epic',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Camo',
        rarity: '11.11',
        rarity_perc: '9.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Laser Eyes',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '234',
    name: 'Maximus',
    stars: '1.38',
    rank: '161',
    score: '59.53',
    image: '/assets/nfts/234.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Mist',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Forest',
        rarity: '8.33',
        rarity_perc: '12.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Plasma',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
      {
        trait_type: '7 Eyes',
        value: 'Doge',
        rarity: '12.50',
        rarity_perc: '8.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '235',
    name: 'Maximus',
    stars: '2.44',
    rank: '4',
    score: '124.72',
    image: '/assets/nfts/235.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Mech War',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Epic',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Ice',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Doge',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '7 Eyes',
        value: 'Nuke',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '236',
    name: 'Maximus',
    stars: '1.15',
    rank: '257',
    score: '45.8',
    image: '/assets/nfts/236.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Mist',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Frosty',
        rarity: '6.82',
        rarity_perc: '14.67',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Rocket',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '237',
    name: 'Maximus',
    stars: '1.78',
    rank: '69',
    score: '84.06',
    image: '/assets/nfts/237.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Fire',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 009',
        rarity: '27.27',
        rarity_perc: '3.67',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Camo',
        rarity: '6.98',
        rarity_perc: '14.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Desert',
        rarity: '8.11',
        rarity_perc: '12.33',
      },
      {
        trait_type: '6 Weapons',
        value: 'Flamer',
        rarity: '13.04',
        rarity_perc: '7.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '238',
    name: 'Maximus',
    stars: '1.68',
    rank: '91',
    score: '77.89',
    image: '/assets/nfts/238.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Nuke',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Camo',
        rarity: '6.98',
        rarity_perc: '14.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Desert',
        rarity: '8.11',
        rarity_perc: '12.33',
      },
      {
        trait_type: '6 Weapons',
        value: 'Flamer',
        rarity: '13.04',
        rarity_perc: '7.67',
      },
      {
        trait_type: '7 Eyes',
        value: 'Laser Beams',
        rarity: '14.29',
        rarity_perc: '7.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '239',
    name: 'Maximus',
    stars: '2.02',
    rank: '20',
    score: '98.99',
    image: '/assets/nfts/239.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Showroom',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 809',
        rarity: '42.86',
        rarity_perc: '2.33',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Meteor',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Desert',
        rarity: '8.11',
        rarity_perc: '12.33',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Shotgun',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '240',
    name: 'Maximus',
    stars: '1.25',
    rank: '214',
    score: '51.57000000000001',
    image: '/assets/nfts/240.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Wind',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Epic',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Doge',
        rarity: '12.50',
        rarity_perc: '8.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '241',
    name: 'Maximus',
    stars: '1.35',
    rank: '169',
    score: '57.7',
    image: '/assets/nfts/241.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Gloom',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Neon',
        rarity: '9.09',
        rarity_perc: '11.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Shotgun',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '242',
    name: 'Maximus',
    stars: '1.63',
    rank: '107',
    score: '74.91',
    image: '/assets/nfts/242.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Gloom',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Frosty',
        rarity: '7.50',
        rarity_perc: '13.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Forest',
        rarity: '8.33',
        rarity_perc: '12.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Shotgun',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '7 Eyes',
        value: 'Doge',
        rarity: '12.50',
        rarity_perc: '8.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '243',
    name: 'Maximus',
    stars: '1.86',
    rank: '50',
    score: '89.07',
    image: '/assets/nfts/243.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Showroom',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 909',
        rarity: '27.27',
        rarity_perc: '3.67',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Ice',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bubble Gun',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '244',
    name: 'Maximus',
    stars: '1.15',
    rank: '258',
    score: '45.769999999999996',
    image: '/assets/nfts/244.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Mist',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Meteor',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Frosty',
        rarity: '6.82',
        rarity_perc: '14.67',
      },
      {
        trait_type: '6 Weapons',
        value: 'Toxic Blaster',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '245',
    name: 'Maximus',
    stars: '1.40',
    rank: '155',
    score: '61.019999999999996',
    image: '/assets/nfts/245.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Wind',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Spacy',
        rarity: '7.14',
        rarity_perc: '14.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Forest',
        rarity: '8.33',
        rarity_perc: '12.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Oldtimer',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
      {
        trait_type: '7 Eyes',
        value: 'Laser Beams',
        rarity: '14.29',
        rarity_perc: '7.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '246',
    name: 'Maximus',
    stars: '1.30',
    rank: '191',
    score: '54.67',
    image: '/assets/nfts/246.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Aurora',
        rarity: '15.00',
        rarity_perc: '6.67',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Assault',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '247',
    name: 'Maximus',
    stars: '1.59',
    rank: '116',
    score: '72.4',
    image: '/assets/nfts/247.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Nuke',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Ice',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Laser Eyes',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '248',
    name: 'Maximus',
    stars: '1.00',
    rank: '296',
    score: '36.43',
    image: '/assets/nfts/248.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Clouds',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Desert',
        rarity: '8.11',
        rarity_perc: '12.33',
      },
      {
        trait_type: '6 Weapons',
        value: 'Flamer',
        rarity: '13.04',
        rarity_perc: '7.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '249',
    name: 'Maximus',
    stars: '1.18',
    rank: '232',
    score: '47.58',
    image: '/assets/nfts/249.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Clouds',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Camo',
        rarity: '6.98',
        rarity_perc: '14.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Ice',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Sniper',
        rarity: '23.08',
        rarity_perc: '4.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '250',
    name: 'Maximus',
    stars: '1.89',
    rank: '41',
    score: '90.60999999999999',
    image: '/assets/nfts/250.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Sparks',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 009',
        rarity: '27.27',
        rarity_perc: '3.67',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Spacy',
        rarity: '7.14',
        rarity_perc: '14.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Camo',
        rarity: '11.11',
        rarity_perc: '9.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Futu Rifle',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '251',
    name: 'Maximus',
    stars: '1.85',
    rank: '52',
    score: '88.61999999999999',
    image: '/assets/nfts/251.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Fire',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 909',
        rarity: '27.27',
        rarity_perc: '3.67',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Meteor',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Forest',
        rarity: '8.33',
        rarity_perc: '12.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Shotgun',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '252',
    name: 'Maximus',
    stars: '1.17',
    rank: '239',
    score: '47.22',
    image: '/assets/nfts/252.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Gloom',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Camo',
        rarity: '6.98',
        rarity_perc: '14.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Futu Rifle',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '253',
    name: 'Maximus',
    stars: '1.73',
    rank: '78',
    score: '81.34',
    image: '/assets/nfts/253.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Sparks',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 38',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Railgun',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '254',
    name: 'Maximus',
    stars: '1.26',
    rank: '207',
    score: '52.769999999999996',
    image: '/assets/nfts/254.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Gloom',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Camo',
        rarity: '6.98',
        rarity_perc: '14.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Camo',
        rarity: '11.11',
        rarity_perc: '9.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Futu Rifle',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '255',
    name: 'Maximus',
    stars: '1.20',
    rank: '224',
    score: '48.980000000000004',
    image: '/assets/nfts/255.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Clouds',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Camo',
        rarity: '6.98',
        rarity_perc: '14.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Neon',
        rarity: '9.09',
        rarity_perc: '11.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Sniper',
        rarity: '23.08',
        rarity_perc: '4.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '256',
    name: 'Maximus',
    stars: '1.56',
    rank: '120',
    score: '70.48',
    image: '/assets/nfts/256.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Wind',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Epic',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Neon',
        rarity: '9.09',
        rarity_perc: '11.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Lightsabers',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '257',
    name: 'Maximus',
    stars: '1.12',
    rank: '271',
    score: '43.8',
    image: '/assets/nfts/257.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Mist',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Camo',
        rarity: '6.98',
        rarity_perc: '14.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Toxic Blaster',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '258',
    name: 'Maximus',
    stars: '1.46',
    rank: '143',
    score: '64.81',
    image: '/assets/nfts/258.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Mech War 2',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Ice',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Doge',
        rarity: '12.50',
        rarity_perc: '8.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '259',
    name: 'Maximus',
    stars: '1.27',
    rank: '206',
    score: '53.08',
    image: '/assets/nfts/259.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Wind',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Spacy',
        rarity: '7.14',
        rarity_perc: '14.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Camo',
        rarity: '11.11',
        rarity_perc: '9.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bubble Gun',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '260',
    name: 'Maximus',
    stars: '2.06',
    rank: '17',
    score: '101.44',
    image: '/assets/nfts/260.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Toxic',
        rarity: '17.65',
        rarity_perc: '5.67',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 809',
        rarity: '42.86',
        rarity_perc: '2.33',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Frosty',
        rarity: '6.82',
        rarity_perc: '14.67',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Assault',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '261',
    name: 'Maximus',
    stars: '2.14',
    rank: '15',
    score: '105.91',
    image: '/assets/nfts/261.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Gold',
        rarity: '27.27',
        rarity_perc: '3.67',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 809',
        rarity: '42.86',
        rarity_perc: '2.33',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Paint',
        rarity: '10.00',
        rarity_perc: '10.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Shotgun',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '262',
    name: 'Maximus',
    stars: '1.69',
    rank: '87',
    score: '78.97',
    image: '/assets/nfts/262.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Sparks',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 880',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Frosty',
        rarity: '6.82',
        rarity_perc: '14.67',
      },
      {
        trait_type: '6 Weapons',
        value: 'Flamer',
        rarity: '13.04',
        rarity_perc: '7.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '263',
    name: 'Maximus',
    stars: '1.95',
    rank: '31',
    score: '94.71000000000001',
    image: '/assets/nfts/263.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Mech War 2',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Epic',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Nuke',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '264',
    name: 'Maximus',
    stars: '1.11',
    rank: '274',
    score: '43.53',
    image: '/assets/nfts/264.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Mist',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Frosty',
        rarity: '6.82',
        rarity_perc: '14.67',
      },
      {
        trait_type: '6 Weapons',
        value: 'Oldtimer',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '265',
    name: 'Maximus',
    stars: '1.70',
    rank: '86',
    score: '79.28999999999999',
    image: '/assets/nfts/265.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Aurora',
        rarity: '15.00',
        rarity_perc: '6.67',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 555',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Spacy',
        rarity: '7.14',
        rarity_perc: '14.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Frosty',
        rarity: '6.82',
        rarity_perc: '14.67',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Shotgun',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '266',
    name: 'Maximus',
    stars: '1.88',
    rank: '42',
    score: '90.6',
    image: '/assets/nfts/266.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Showroom',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 009',
        rarity: '27.27',
        rarity_perc: '3.67',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Camo',
        rarity: '6.98',
        rarity_perc: '14.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Ice',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Assault',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '267',
    name: 'Maximus',
    stars: '1.63',
    rank: '108',
    score: '74.87',
    image: '/assets/nfts/267.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Sparks',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 555',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Neon',
        rarity: '9.09',
        rarity_perc: '11.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Sniper',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '268',
    name: 'Maximus',
    stars: '1.18',
    rank: '237',
    score: '47.33',
    image: '/assets/nfts/268.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Mist',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Camo',
        rarity: '6.98',
        rarity_perc: '14.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Neon',
        rarity: '9.09',
        rarity_perc: '11.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Carbine',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '269',
    name: 'Maximus',
    stars: '1.85',
    rank: '55',
    score: '88.37',
    image: '/assets/nfts/269.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Fire',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 009',
        rarity: '27.27',
        rarity_perc: '3.67',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Carbine',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '270',
    name: 'Maximus',
    stars: '1.81',
    rank: '61',
    score: '86.08',
    image: '/assets/nfts/270.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Mech War',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Frosty',
        rarity: '6.82',
        rarity_perc: '14.67',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Plasma',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
      {
        trait_type: '7 Eyes',
        value: 'Laser Eyes',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '271',
    name: 'Maximus',
    stars: '1.37',
    rank: '162',
    score: '58.989999999999995',
    image: '/assets/nfts/271.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Mist',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Frosty',
        rarity: '7.50',
        rarity_perc: '13.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Forest',
        rarity: '8.33',
        rarity_perc: '12.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Assault',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '272',
    name: 'Maximus',
    stars: '1.13',
    rank: '265',
    score: '44.54',
    image: '/assets/nfts/272.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Mist',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Rocket',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '273',
    name: 'Maximus',
    stars: '2.06',
    rank: '18',
    score: '101.2',
    image: '/assets/nfts/273.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Fire',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 880',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Camo',
        rarity: '6.98',
        rarity_perc: '14.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Electro Gun',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '274',
    name: 'Maximus',
    stars: '1.64',
    rank: '102',
    score: '75.73',
    image: '/assets/nfts/274.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Future',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Camo',
        rarity: '11.11',
        rarity_perc: '9.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Rocket',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '275',
    name: 'Maximus',
    stars: '1.76',
    rank: '71',
    score: '82.91',
    image: '/assets/nfts/275.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Aurora',
        rarity: '15.00',
        rarity_perc: '6.67',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 909',
        rarity: '27.27',
        rarity_perc: '3.67',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Camo',
        rarity: '6.98',
        rarity_perc: '14.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Paint',
        rarity: '10.00',
        rarity_perc: '10.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Sniper',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '276',
    name: 'Maximus',
    stars: '1.14',
    rank: '261',
    score: '45.21',
    image: '/assets/nfts/276.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Mist',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Meteor',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Ice',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Bfg',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '277',
    name: 'Maximus',
    stars: '1.95',
    rank: '30',
    score: '94.72',
    image: '/assets/nfts/277.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Mech War 2',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Epic',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Ice',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Doge',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '278',
    name: 'Maximus',
    stars: '1.78',
    rank: '67',
    score: '84.5',
    image: '/assets/nfts/278.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Aurora',
        rarity: '15.00',
        rarity_perc: '6.67',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 909',
        rarity: '27.27',
        rarity_perc: '3.67',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Spacy',
        rarity: '7.14',
        rarity_perc: '14.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Paint',
        rarity: '10.00',
        rarity_perc: '10.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Futu Rifle',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '279',
    name: 'Maximus',
    stars: '1.35',
    rank: '167',
    score: '57.92',
    image: '/assets/nfts/279.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Mist',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Camo',
        rarity: '6.98',
        rarity_perc: '14.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Ice',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Electro Gun',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '280',
    name: 'Maximus',
    stars: '2.25',
    rank: '9',
    score: '112.59',
    image: '/assets/nfts/280.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Nuke',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Epic',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Doge',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '7 Eyes',
        value: 'Rocket',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '281',
    name: 'Maximus',
    stars: '1.27',
    rank: '204',
    score: '53.11',
    image: '/assets/nfts/281.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Aurora',
        rarity: '15.00',
        rarity_perc: '6.67',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Neon',
        rarity: '9.09',
        rarity_perc: '11.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Toxic Blaster',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '282',
    name: 'Maximus',
    stars: '1.46',
    rank: '144',
    score: '64.55',
    image: '/assets/nfts/282.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Mist',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Camo',
        rarity: '6.98',
        rarity_perc: '14.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Frosty',
        rarity: '6.82',
        rarity_perc: '14.67',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bubble Gun',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
      {
        trait_type: '7 Eyes',
        value: 'Doge',
        rarity: '12.50',
        rarity_perc: '8.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '283',
    name: 'Maximus',
    stars: '1.27',
    rank: '203',
    score: '53.15',
    image: '/assets/nfts/283.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Sparks',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Bfg',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '284',
    name: 'Maximus',
    stars: '1.34',
    rank: '173',
    score: '57.33',
    image: '/assets/nfts/284.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Wind',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Frosty',
        rarity: '7.50',
        rarity_perc: '13.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Paint',
        rarity: '10.00',
        rarity_perc: '10.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Railgun',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '285',
    name: 'Maximus',
    stars: '1.29',
    rank: '195',
    score: '54.22',
    image: '/assets/nfts/285.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Clouds',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Frosty',
        rarity: '6.82',
        rarity_perc: '14.67',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Moneybag',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '286',
    name: 'Maximus',
    stars: '1.29',
    rank: '194',
    score: '54.54',
    image: '/assets/nfts/286.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Aurora',
        rarity: '15.00',
        rarity_perc: '6.67',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Frosty',
        rarity: '7.50',
        rarity_perc: '13.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Frosty',
        rarity: '6.82',
        rarity_perc: '14.67',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Sniper',
        rarity: '23.08',
        rarity_perc: '4.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '287',
    name: 'Maximus',
    stars: '1.19',
    rank: '226',
    score: '48.4',
    image: '/assets/nfts/287.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Mist',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Spacy',
        rarity: '7.14',
        rarity_perc: '14.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Paint',
        rarity: '10.00',
        rarity_perc: '10.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Futu Rifle',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '288',
    name: 'Maximus',
    stars: '1.54',
    rank: '124',
    score: '69.34',
    image: '/assets/nfts/288.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Clouds',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Meteor',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Frosty',
        rarity: '6.82',
        rarity_perc: '14.67',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bubble Gun',
        rarity: '25.00',
        rarity_perc: '4.00',
      },
      {
        trait_type: '7 Eyes',
        value: 'Rocket',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '289',
    name: 'Maximus',
    stars: '1.17',
    rank: '240',
    score: '47.18',
    image: '/assets/nfts/289.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Wind',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Desert',
        rarity: '8.11',
        rarity_perc: '12.33',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Laser Eyes',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '290',
    name: 'Maximus',
    stars: '2.12',
    rank: '16',
    score: '104.72',
    image: '/assets/nfts/290.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Clouds',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Legendary',
        rarity: '50.00',
        rarity_perc: '2.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Paint',
        rarity: '10.00',
        rarity_perc: '10.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Doge',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '291',
    name: 'Maximus',
    stars: '1.10',
    rank: '279',
    score: '43.01',
    image: '/assets/nfts/291.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Clouds',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Desert',
        rarity: '8.11',
        rarity_perc: '12.33',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Doge',
        rarity: '12.50',
        rarity_perc: '8.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '292',
    name: 'Maximus',
    stars: '1.90',
    rank: '35',
    score: '91.8',
    image: '/assets/nfts/292.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Aurora',
        rarity: '15.00',
        rarity_perc: '6.67',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 909',
        rarity: '27.27',
        rarity_perc: '3.67',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Camo',
        rarity: '6.98',
        rarity_perc: '14.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Assault',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '293',
    name: 'Maximus',
    stars: '1.95',
    rank: '29',
    score: '94.73',
    image: '/assets/nfts/293.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Factory',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Lightsabers',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '7 Eyes',
        value: 'Laser Eyes',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '294',
    name: 'Maximus',
    stars: '1.22',
    rank: '219',
    score: '49.870000000000005',
    image: '/assets/nfts/294.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Wind',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Frosty',
        rarity: '7.50',
        rarity_perc: '13.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Ice',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Toxic Blaster',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '295',
    name: 'Maximus',
    stars: '1.42',
    rank: '150',
    score: '62.37',
    image: '/assets/nfts/295.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Gloom',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Spacy',
        rarity: '7.14',
        rarity_perc: '14.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Ice',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Sniper',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
      {
        trait_type: '7 Eyes',
        value: 'Laser Beams',
        rarity: '14.29',
        rarity_perc: '7.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '296',
    name: 'Maximus',
    stars: '2.24',
    rank: '10',
    score: '112.33000000000001',
    image: '/assets/nfts/296.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Rare Battlefront',
        rarity: '33.33',
        rarity_perc: '3.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Legendary',
        rarity: '50.00',
        rarity_perc: '2.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Original',
        rarity: '3.70',
        rarity_perc: '27.00',
      },
      {
        trait_type: '5 Clothes',
        value: 'Vivid',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Original',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '7 Eyes',
        value: 'Laser Beams',
        rarity: '14.29',
        rarity_perc: '7.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '297',
    name: 'Maximus',
    stars: '1.17',
    rank: '243',
    score: '47.03',
    image: '/assets/nfts/297.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Gloom',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Rare',
        rarity: '5.56',
        rarity_perc: '18.00',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Forest',
        rarity: '8.33',
        rarity_perc: '12.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Scifi Plasma',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '298',
    name: 'Maximus',
    stars: '1.13',
    rank: '264',
    score: '44.82',
    image: '/assets/nfts/298.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Common Clouds',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Common',
        rarity: '2.14',
        rarity_perc: '46.67',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Desert',
        rarity: '8.11',
        rarity_perc: '12.33',
      },
      {
        trait_type: '6 Weapons',
        value: 'Toxic Blaster',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '299',
    name: 'Maximus',
    stars: '1.79',
    rank: '63',
    score: '85.0',
    image: '/assets/nfts/299.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Sparks',
        rarity: '20.00',
        rarity_perc: '5.00',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 555',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Camo',
        rarity: '6.98',
        rarity_perc: '14.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Ice',
        rarity: '7.69',
        rarity_perc: '13.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Shotgun',
        rarity: '30.00',
        rarity_perc: '3.33',
      },
    ],
    compiler: 'nft-inator.com',
  },
  {
    id: '300',
    name: 'Maximus',
    stars: '1.63',
    rank: '109',
    score: '74.86000000000001',
    image: '/assets/nfts/300.webp',
    description: 'Maximus Sniper',
    external_url: '',
    attributes: [
      {
        trait_type: '1 Backgrounds',
        value: 'Unc Toxic',
        rarity: '17.65',
        rarity_perc: '5.67',
      },
      {
        trait_type: '2 Robot',
        value: 'Robot 38',
        rarity: '16.67',
        rarity_perc: '6.00',
      },
      {
        trait_type: '3 Frame',
        value: 'Uncommon',
        rarity: '3.66',
        rarity_perc: '27.33',
      },
      {
        trait_type: '4 Vests',
        value: 'Icy',
        rarity: '5.45',
        rarity_perc: '18.33',
      },
      {
        trait_type: '5 Clothes',
        value: 'Paint',
        rarity: '10.00',
        rarity_perc: '10.00',
      },
      {
        trait_type: '6 Weapons',
        value: 'Bsc Carbine',
        rarity: '21.43',
        rarity_perc: '4.67',
      },
    ],
    compiler: 'nft-inator.com',
  },
];
