import { useEffect } from 'react';

import { Button } from '@mui/material';
// hooks
import { useMoralis } from 'react-moralis';
import { useSnackbar } from 'notistack';

// utils
// config

// components
import Iconify from '../../../components/Iconify';
import NavbarAccount from './NavbarAccount';
import { HOST_URL } from '../../../config';

// ----------------------------------------------------------------------

export default function NavbarUser() {
  const { authenticate, isAuthenticated, user, logout } = useMoralis();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isAuthenticated) {
      // add your logic here
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const login = async () => {
    if (!isAuthenticated) {
      await authenticate({ signingMessage: 'Welcome to Maximus Sniper Dapp!' })
        .then((user) => {
          console.log(user?.get('ethAddress'));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const logOut = async () => {
    await logout();
    console.log('logged out');
    enqueueSnackbar('Logged out!', { variant: 'success' });
  };

  const openInMetamask = async () => {
    try {
      window.location.replace(`https://metamask.app.link/dapp/${HOST_URL}`);
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  return (
    <>
      {user && <NavbarAccount />}
      {!user && (
        <Button variant="contained" onClick={login}>
          Login
        </Button>
      )}
      {!user && (
        <Button variant="contained" onClick={openInMetamask} color="warning">
          Open in <Iconify icon={'logos:metamask-icon'} sx={{ mx: 1, width: 20, height: 20 }} /> App
        </Button>
      )}
      {user && (
        <Button variant="contained" onClick={logOut}>
          Logout
        </Button>
      )}
    </>
  );
}
