// @mui
import { Stack, Button, Typography } from '@mui/material';
// assets
import { DocIllustration } from '../../../assets';

// ----------------------------------------------------------------------

export default function NavbarDocs() {
  return (
    <Stack spacing={3} sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: 'center', display: 'block' }}>
      <DocIllustration sx={{ width: 1 }} />
      <Button variant="contained" href="https://www.solidapps.io/" target="_blank">
        Powered by Solidapps
      </Button>
      <div>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Contact us
          <br /> Need help for your next project?
        </Typography>
      </div>
    </Stack>
  );
}
