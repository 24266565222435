// @mui
import { enUS, frFR } from '@mui/material/locale';

// API
// ----------------------------------------------------------------------
export const HOST_API = process.env.REACT_APP_HOST_API_KEY || 'https://deep-index.moralis.io/api/v2/';

export const CONTRACT_ADDRESS = '0xeb800FD2cc60FfCC3bF15eBE25b6A335Cd5cF6e6'; // '0x605f4bf28222674efbc5ae91c576444ca62e1c3a'; // '0x30919E0167222F267De1692CE3e8E6630C784cB9'; // 0xb0548bd6B47b8449449E56C2d03646fa3bE9B2D0
export const NFT_CONTRACT_ADDRESS = '0x582D66Ab5124d10eFd228a0796aBF47afd3f410f'; // '0x2180165B01eEaF93D27A3aa917BBa26aE98f5a65';
export const CONTRACT_ADDRESS_BID = '0x458906bC1fFD2e5C1054A8d829fB960b60Bb223c'; // '0x8871504CFc0E522abB07Dae1EDb2dCF21A9E2a46';
export const CONTRACT_ADDRESS_PAY = '0x79e7d11Ce8B78c58a01ebbA50e27031cDcEf0365'; // '0xba265524679Cdf588d9C79d4b41C43d7Ee84c24c';
export const TOKEN_ADDRESS = '0xBA4F8bBEE30181678ECB14564D78Db39d223E960'; // '0x2180165B01eEaF93D27A3aa917BBa26aE98f5a65'; // '0xA84AfF5437CCC65E763bF31CD409866F08E902CB'; // '0xa39c843ccfdb3ede58a09efb5564e34c43099480';
export const TOKEN_CONTRACT_ADDRESS = '0xBA4F8bBEE30181678ECB14564D78Db39d223E960'; // '0x2180165B01eEaF93D27A3aa917BBa26aE98f5a65'; // 0xA84AfF5437CCC65E763bF31CD409866F08E902CB'; // '0xa39c843ccfdb3ede58a09efb5564e34c43099480';

// export const CHAIN_ID = '420';
// export const CHAIN_ID_MORALIS = '0x5';
// export const CHAIN_NAME = 'goerli';

export const CHAIN_ID = '1';
export const CHAIN_ID_MORALIS = '0x1';
export const CHAIN_NAME = 'eth';

export const PROD_ENV = true;
export const HOST_URL = 'dapp.maximussniper.ai'; // 'purple-desert-0bae85a03.2.azurestaticapps.net';
export const EXPLORER_URL = 'etherscan.io';
// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const defaultSettings = {
  themeMode: 'dark',
  themeDirection: 'ltr',
  themeContrast: 'default',
  themeLayout: 'vertical',
  themeColorPresets: 'orange',
  themeStretch: false,
};

// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: '/assets/icons/flags/ic_flag_en.svg',
  },
  {
    label: 'French',
    value: 'fr',
    systemValue: frFR,
    icon: '/assets/icons/flags/ic_flag_fr.svg',
  },
];

export const defaultLang = allLangs[0]; // English
