import { createSlice } from '@reduxjs/toolkit';
import { ethers } from 'ethers';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
import { NFT_CONTRACT_ADDRESS, CONTRACT_ADDRESS_BID, CHAIN_NAME } from '../../config';
// import { products } from '../../_mock/product';
import { nfts } from '../../_mock/nft';

// ---------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  nfts: [],
  nft: null,
  nftsUser: [],
  isEnabled: null,
  sortBy: null,
  nftStats: [],
  filters: {
    gender: [],
    category: 'All',
    colors: [],
    priceRange: [0, 200],
    rating: '',
  },
};

const slice = createSlice({
  name: 'nft',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
      state.nft = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getNftsSuccess(state, action) {
      state.isLoading = false;
      state.nfts = action.payload;
    },

    // GET NFTS FOR USER
    getNftsUserSuccess(state, action) {
      state.isLoading = false;
      state.nftsUser = action.payload;
    },

    // GET NFTS FOR USER
    getNumberMintedSuccess(state, action) {
      state.isLoading = false;
      state.nftStats = action.payload;
    },

    // GET PRODUCT
    getNftSuccess(state, action) {
      state.isLoading = false;
      state.nft = action.payload;
    },

    // GET PRODUCT
    getIsEnabledSuccess(state, action) {
      state.isLoading = false;
      state.isEnabled = action.payload;
    },

    filterProducts(state, action) {
      state.filters.gender = action.payload.gender;
      state.filters.category = action.payload.category;
      state.filters.colors = action.payload.colors;
      state.filters.priceRange = action.payload.priceRange;
      state.filters.rating = action.payload.rating;
    },
  },
});

// Reducer
export default slice.reducer;
// Actions
export const { filterProducts } = slice.actions;

// ----------------------------------------------------------------------

export function getNfts() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const NumberNftMinted = await getNumberNftMinted();
      const nft = nfts.slice(0, NumberNftMinted).reverse();
      dispatch(slice.actions.getNftsSuccess(nft));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getNft(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const json = await getNftjson(id);
      if (json) {
        const nft = await getNftMetadata(json, id);
        const messages = await getNftComments(id);
        console.log(messages);
        nft.messages = messages;
        if (messages.length > 0) {
          nft.totalBid = messages.reduce((acc, message) => acc + message.bid, 0);
          nft.averageBid = +nft.totalBid / +nft.messages.length;
          nft.maxBid = Math.max(...messages.map((o) => o.bid));
        }
        // console.log('nft', nft);
        dispatch(slice.actions.getNftSuccess(nft));
      } else {
        throw new Error('Could not load nft');
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getNftsUser(address) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const nfts = await getNftsForAddress(address);
      dispatch(slice.actions.getNftsUserSuccess(nfts));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getNftStats() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const numberNftMinted = await getNumberNftMinted();
      const currentSupply = await getSupplyForEpoch();

      const numberNftMintedBatch = numberNftMinted - 50;
      const isEnabled = currentSupply > 0;
      dispatch(slice.actions.getIsEnabledSuccess(isEnabled));
      const stats = [
        {
          status: 'Batch availability',
          quantity: currentSupply - numberNftMintedBatch,
          total: currentSupply,
          value: ((100 - numberNftMintedBatch) * 100) / 100,
        },
        {
          status: 'Total available',
          quantity: 300 - numberNftMinted,
          total: '300',
          value: ((300 - numberNftMinted) * 100) / 300,
        },
      ];
      // console.log(stats);
      dispatch(slice.actions.getNumberMintedSuccess(stats));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Get NftJson Metadatas
async function getNftsForAddress(address) {
  try {
    const response = await axios.get(`${address}/nft/${NFT_CONTRACT_ADDRESS}?chain=${CHAIN_NAME}&format=decimal`);
    // We find the correct nfts in json based on the id returned by metamask
    return response.data.result.map((nft) => nfts.find((o) => o.id === nft.token_id));
  } catch (error) {
    console.error(error);
    return null;
  }
}

// Get NftJson for Id
async function getNumberNftMinted() {
  const options = {
    abi: [
      {
        inputs: [],
        name: 'totalSupply',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
      },
    ],
    contractAddress: NFT_CONTRACT_ADDRESS,
    functionName: 'totalSupply',
  };
  try {
    const response = await axios.post(
      `${NFT_CONTRACT_ADDRESS}/function?chain=${CHAIN_NAME}&function_name=totalSupply`,
      {
        abi: options.abi,
      }
    );
    return response.data.toString();
  } catch (error) {
    console.error(error);
    return null;
  }
}

// Get NftJson for Id
async function getSupplyForEpoch() {
  const options = {
    abi: [
      {
        inputs: [],
        name: 'currentSupply',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
      },
    ],
    contractAddress: NFT_CONTRACT_ADDRESS,
    functionName: 'currentSupply',
  };
  try {
    const response = await axios.post(
      `${NFT_CONTRACT_ADDRESS}/function?chain=${CHAIN_NAME}&function_name=currentSupply`,
      {
        abi: options.abi,
      }
    );
    return response.data.toString();
  } catch (error) {
    console.error(error);
    return 0;
  }
  // try {
  //   const customHttpProvider = new ethers.providers.JsonRpcProvider(RPC_ADDRESS);
  //   const abi = [
  //     {
  //       inputs: [],
  //       name: 'currentSupply',
  //       outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
  //       stateMutability: 'view',
  //       type: 'function',
  //     },
  //   ];

  //   const DogeApeNftContract = new ethers.Contract(NFT_CONTRACT_ADDRESS, abi, customHttpProvider);
  //   const currentSupply = (await DogeApeNftContract.currentSupply()).toNumber();
  //   // const response = await axios.post(`${NFT_CONTRACT_ADDRESS}/function?chain=${CHAIN_NAME}&function_name=totalSupply`, {
  //   //   abi: options.abi,
  //   // });
  //   return currentSupply;
  // } catch (error) {
  //   console.error(error);
  //   return null;
  // }
}

// Get NftJson for Id
async function getNftjson(id) {
  const options = {
    abi: [
      {
        inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
        name: 'tokenURI',
        outputs: [{ internalType: 'string', name: '', type: 'string' }],
        stateMutability: 'view',
        type: 'function',
      },
    ],
    contractAddress: NFT_CONTRACT_ADDRESS,
    functionName: 'tokenURI',
    params: {
      tokenId: id,
    },
  };
  try {
    const response = await axios.post(`${NFT_CONTRACT_ADDRESS}/function?chain=${CHAIN_NAME}&function_name=tokenURI`, {
      abi: options.abi,
      params: { tokenId: id },
    });
    return response.data.toString();
  } catch (error) {
    console.error(error);
    return null;
  }
}

// Get NftJson for Id
async function getNftComments(id) {
  try {
    const options = {
      abi: [
        {
          inputs: [
            {
              internalType: 'uint256',
              name: '_nftId',
              type: 'uint256',
            },
          ],
          name: 'getMessages',
          outputs: [
            {
              components: [
                {
                  internalType: 'address',
                  name: 'sender',
                  type: 'address',
                },
                {
                  internalType: 'string',
                  name: 'message',
                  type: 'string',
                },
                {
                  internalType: 'uint256',
                  name: 'bid',
                  type: 'uint256',
                },
                {
                  internalType: 'uint256',
                  name: 'timestamp',
                  type: 'uint256',
                },
              ],
              internalType: 'struct bidMessages.Message[]',
              name: '',
              type: 'tuple[]',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
      ],
      contractAddress: CONTRACT_ADDRESS_BID,
      functionName: 'getMessages',
      params: {
        _nftId: id,
      },
    };

    const messagesResp = await axios.post(
      `${CONTRACT_ADDRESS_BID}/function?chain=${CHAIN_NAME}&function_name=getMessages`,
      {
        abi: options.abi,
        params: { _nftId: id },
      }
    );

    const messages = messagesResp.data
      .map((message) => {
        const currentMessage = {
          sender: message[0],
          content: message[1],
          bid: +ethers.utils.formatEther(ethers.BigNumber.from(message[2])),
          timestamp: message[3],
          postedAt: message[3] * 1000,
        };
        if (
          message.sender === '0xbAEa06623F86784Dd49b517B6E67A9F8EF4C87Ff' ||
          message.sender === '0x0C197d697B293Ed8EcfE60D06cEF17862cA9b194'
        ) {
          currentMessage.isSolidapps = true;
        }
        return currentMessage;
      })
      .sort((a, b) => b.bid - a.bid);

    // console.log('messages', messages);
    return messages;
  } catch (error) {
    console.error(error);
    return [];
  }
}

// Get NftJson Metadatas
async function getNftMetadata(json, id) {
  try {
    const metadatas = await fetch(json).then((response) => response.json());
    const localMetadatas = nfts.find((o) => o.id === id);
    // console.log('localMetadatas', localMetadatas);
    return {
      json,
      id,
      rank: localMetadatas?.rank,
      stars: null, // +localMetadatas?.stars,
      attributes: localMetadatas?.attributes,
      name: `${metadatas.name} #${id}`,
      images: [metadatas.image],
      description: `\n<p><strong>${
        metadatas.description
      }</strong></p>\n<br />\n<p><strong><small>ATTRIBUTES</small></strong></p>\n<p>${localMetadatas.attributes.map(
        (attribute) =>
          `  <small>${attribute.trait_type}:</small> <strong>${attribute.value}</strong>  <small>(${attribute.rarity_perc}%)</small>`
      )}</p>`,
    };
  } catch (error) {
    console.error(error);
    return null;
  }
}
