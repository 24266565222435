import PropTypes from 'prop-types';
import { useMoralis } from 'react-moralis';
import { useEffect } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, Typography, Card } from '@mui/material';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// redux
import { useDispatch, useSelector } from '../../../redux/store';
import { getTokenUserBalance, getNativeBalance } from '../../../redux/slices/snipe';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER, NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';
//

import AccountPopover from './AccountPopover';
import ChainPopover from './ChainPopover';
import WalletPopover from './WalletPopover';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
};

export default function DashboardHeader({ onOpenSidebar, isCollapse = false, verticalLayout = false }) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  const { user } = useMoralis();
  const isDesktop = useResponsive('up', 'lg');

  const dispatch = useDispatch();
  useEffect(() => {
    if (user) {
      dispatch(getTokenUserBalance(user.get('ethAddress')));
      dispatch(getNativeBalance(user.get('ethAddress')));
    }
  }, [dispatch, user]);
  const { tokenBalance, nativeBalance } = useSelector((state) => state.snipe);

  return (
    <RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 },
        }}
      >
        {false && isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

        {!isDesktop && (
          <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {user && <AmmunitionCard tokenBalance={tokenBalance} nativeBalance={nativeBalance} />}
          {user && <WalletPopover tokenBalance={tokenBalance} nativeBalance={nativeBalance} />}
          {user && <ChainPopover />}
          {user && <AccountPopover />}
        </Stack>
      </Toolbar>
    </RootStyle>
  );
}

AmmunitionCard.propTypes = {
  tokenBalance: PropTypes.string,
  nativeBalance: PropTypes.string,
};

function AmmunitionCard({ tokenBalance, nativeBalance }) {
  return (
    <Card sx={{ display: { xs: 'none', sm: 'flex' }, alignItems: 'center', p: 1 }}>
      <Typography variant="subtitle2" noWrap sx={{ flexGrow: 1, minWidth: 0, pl: 1, color: 'text.secondary' }}>
        Ammunition
      </Typography>
      <Box sx={{ flexGrow: 1, minWidth: 0, pl: 1, pr: 1 }}>
        <Typography variant="body2" noWrap>
          {nativeBalance === null ? '0' : nativeBalance} ETH
        </Typography>
      </Box>

      <Typography variant="body2" sx={{ color: 'text.secondary', display: { xs: 'none', sm: 'block' } }}>
        |
      </Typography>
      <Box sx={{ flexGrow: 1, minWidth: 0, pl: 1, pr: 1, display: { xs: 'none', sm: 'block' } }}>
        <Typography variant="body2" noWrap>
          {tokenBalance === null ? '0' : tokenBalance} Maximus
        </Typography>
      </Box>
    </Card>
  );
}
