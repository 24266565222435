import axios from 'axios';
// config
import { HOST_API } from '../config';

// ----------------------------------------------------------------------
const axiosInstance = axios.create({
  baseURL: HOST_API,
  headers: {
    'x-api-key': 'rMi23Uh6s11gyN4Dg6TcNKmlFhVqQI506NdKKxXx4lpidMf3evI533x63R6mEUcg',
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
