import { useState } from 'react';
import { useMoralis } from 'react-moralis';
import { useSnackbar } from 'notistack';
// @mui
import { MenuItem, Stack, Badge } from '@mui/material';
// components
import Iconify from '../../../components/Iconify';
import Image from '../../../components/Image';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import { CHAIN_ID_MORALIS } from '../../../config';

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const { Moralis, enableWeb3, chainId, isWeb3Enabled } = useMoralis();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(null);
  const chains = [
    // {
    //   label: 'BSC',
    //   chainid: 56,
    //   chainidMoralis: '0x38',
    //   value: 'bsc',
    //   icon: '/assets/icons/bnb.png',
    //   chainName: 'bsc',
    //   currencyName: 'BNB',
    //   currencySymbol: 'BNB',
    // },
    {
      label: 'ETH (Mainnet)',
      chainid: 1,
      chainidMoralis: '0x1',
      value: 'eth',
      icon: '/assets/icons/eth.png',
      chainName: 'eth',
      currencyName: 'ETH',
      currencySymbol: 'ETH',
    },
    // {
    //   label: 'ETH (Testnet)',
    //   chainid: 420,
    //   chainidMoralis: '0x1a4',
    //   value: 'eth',
    //   icon: '/assets/icons/eth.png',
    //   chainName: 'goerli',
    //   currencyName: 'TETH',
    //   currencySymbol: 'TETH',
    // },
  ];

  const handleWeb3 = () => {
    enableWeb3();
  };

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = async (chain) => {
    try {
      if (chain.chainidMoralis !== chainId) {
        await enableWeb3();
        await Moralis.switchNetwork(chain.chainid);
        enqueueSnackbar(`You are now on ${chain.label}`, { variant: 'success', autoHideDuration: 5000 });
      } else {
        enqueueSnackbar(`You are already on ${chain.label}`, { variant: 'warning', autoHideDuration: 5000 });
      }
    } catch (e) {
      console.error(e.message);
    }
    setOpen(null);
  };

  return (
    <>
      {!isWeb3Enabled && (
        <IconButtonAnimate
          onClick={handleWeb3}
          sx={{
            width: 40,
            height: 40,
            ...(open && { bgcolor: 'action.selected' }),
          }}
        >
          <Iconify icon={'akar-icons:link-chain'} width={20} height={20} />
        </IconButtonAnimate>
      )}
      {isWeb3Enabled && (
        <IconButtonAnimate
          onClick={handleOpen}
          sx={{
            width: 40,
            height: 40,
            ...(open && { bgcolor: 'action.selected' }),
          }}
        >
          {chainId !== CHAIN_ID_MORALIS && (
            <Badge badgeContent="!" color="error">
              <Iconify icon={'akar-icons:link-chain'} width={20} height={20} />
            </Badge>
          )}
          {chainId === CHAIN_ID_MORALIS && <Iconify icon={'akar-icons:link-chain'} width={20} height={20} />}
        </IconButtonAnimate>
      )}

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <Stack spacing={0.75}>
          {chains.map((option) => (
            <MenuItem
              key={option.chainid}
              selected={option.chainidMoralis === chainId}
              onClick={() => handleClose(option)}
            >
              <Image disabledEffect alt={option.label} src={option.icon} sx={{ width: 28, mr: 2 }} />

              {option.label}
            </MenuItem>
          ))}
          <MenuItem key="chainlist" onClick={() => window.open('https://chainlist.org', '_blank').focus()}>
            <Iconify icon={'eva:external-link-outline'} width={20} height={20} sx={{ width: 28, mr: 2 }} />
            Open ChainList
          </MenuItem>
        </Stack>
      </MenuPopover>
    </>
  );
}
