// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  user: getIcon('ic_user'),
  sniper: getIcon('ic_sniper'),
  blog: getIcon('ic_blog'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  invoice: getIcon('ic_invoice'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'NFTs',
    items: [
      { title: 'NFT Mint', path: '/mint', icon: ICONS.ecommerce },
      { title: 'Gallery', path: '/gallery', icon: ICONS.calendar },
    ],
  },
  {
    subheader: 'Maximus Sniper',
    items: [
      { title: 'Sniper', path: '/sniper', icon: ICONS.sniper },
      {
        title: 'Your Headquarters',
        path: '/profile',
        icon: ICONS.user,
      },
    ],
  },
  {
    subheader: 'Settings',
    items: [{ title: 'Subscription', path: '/settings', icon: ICONS.invoice }],
  },
];

export default navConfig;
