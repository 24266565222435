import { createSlice } from '@reduxjs/toolkit';
import { ethers } from 'ethers';
// utils
// import axios from '../../utils/axios';
//
import { dispatch } from '../store';
import { CONTRACT_ADDRESS, CHAIN_NAME, TOKEN_ADDRESS, CONTRACT_ADDRESS_PAY } from '../../config';
// import { products } from '../../_mock/product';
import axios from '../../utils/axios';

// ---------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  isApproved: false,
  snipes: [],
  sniper: null,
  sub: null,
  pricePer100: null,
  plans: null,
  balance: null,
  tokenBalance: null,
  nativeBalance: null,
  token: null,
  sortBy: null,
  snipeStats: [],
};

const slice = createSlice({
  name: 'snipe',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
      state.snipe = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setIsApproved(state, action) {
      state.isApproved = action.payload;
    },
    // SETUP SNIPËR
    setupSniperEventSuccess(state, action) {
      const newSnipe = action.payload;
      state.isLoading = false;
      state.snipes = [...state.snipes, newSnipe];
    },

    // Get SNIPËR
    getSniperEventSuccess(state, action) {
      state.isLoading = false;
      state.sniper = action.payload;
    },

    // Get Token Balance
    getBalanceTokenEventSuccess(state, action) {
      state.isLoading = false;
      state.tokenBalance = action.payload;
    },
    // Get Native Balance
    getNativeBalanceTokenEventSuccess(state, action) {
      state.isLoading = false;
      state.nativeBalance = action.payload;
    },
    // Get Native Balance
    getTokenMetadataEventSuccess(state, action) {
      state.isLoading = false;
      state.token = action.payload;
    },
    // Get Native Balance
    getSubStatusSuccess(state, action) {
      state.isLoading = false;
      state.sub = action.payload;
    },
    // Get Native Balance
    getSubPricePer100Success(state, action) {
      state.isLoading = false;
      state.plans = action.payload;
    },
    // Get Native Balance
    getIsApprovedSuccess(state, action) {
      state.isLoading = false;
      state.isApproved = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
// Actions
export const { filterProducts, setIsApproved } = slice.actions;

// ----------------------------------------------------------------------

export function getTokenUserBalance(address) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getTokenBalanceForUser(address);
      dispatch(slice.actions.getBalanceTokenEventSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getIsApproved(address) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const allowed = await getAllowance(address);
      console.log(allowed);
      dispatch(slice.actions.getIsApprovedSuccess(allowed));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTokenPerPrice() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const price = await getTokenPer100DollarPrice();
      const plans = [
        { id: 1, name: 'Basic', details: 'This is the basic plan ', price: price * 2, validity: '1 Month' },
        { id: 2, name: 'Premium', details: 'This is the premium plan', price: price * 3.5, validity: '1 Month' },
      ];
      dispatch(slice.actions.getSubPricePer100Success(plans));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSubStatus(address) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // Check if user can use Basic Sniper
      const canUseSniper = await getCanUseSniperForUser(address);
      if (!canUseSniper) {
        // If not => No Plan
        const subStatus = { canUseSniper, plan: 'No plan subscribed' };
        dispatch(slice.actions.getSubStatusSuccess(subStatus));
        return;
      }
      // If Yes
      // Check if user can use Premium Sniper
      const canUsePremiumSniper = await getCanUsePremiumSniperForUser(address);
      if (canUsePremiumSniper) {
        // Yes can use
        const until = await getCanUsePremiumUntilForUser(address);
        const subStatus = { canUseSniper: canUsePremiumSniper, plan: 'Premium Plan', until, premium: true };
        dispatch(slice.actions.getSubStatusSuccess(subStatus));
        return;
      }
      // No Can't use premium
      // Plan => Basic
      const until = await getCanUseBasicUntilForUser(address);
      const subStatus = { canUseSniper, plan: 'Basic Plan', until, premium: false };
      dispatch(slice.actions.getSubStatusSuccess(subStatus));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTokenMetadata(token) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getTokenMetadataWeb3(token);
      dispatch(slice.actions.getTokenMetadataEventSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getNativeBalance(address) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getNativeBalanceForUser(address);
      dispatch(slice.actions.getNativeBalanceTokenEventSuccess(Number(response).toFixed(3)));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setupSniper(sniperConfig) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      console.log(sniperConfig);
      const response = null;
      dispatch(slice.actions.setupSniperEventSuccess(response.data.event));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSniper(address) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const sniperInfo = await getUserSnperInfo(address);

      sniperInfo.snipers = await getSnipersForToken(sniperInfo.target);
      sniperInfo.token = await getTokenMetadataWeb3(sniperInfo.target);
      sniperInfo.status = sniperInfo.balance > 0 ? 'loaded' : 'not loaded';
      dispatch(slice.actions.getSniperEventSuccess(sniperInfo));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

async function getAllowance(userAddress) {
  try {
    const options = {
      contractAddress: TOKEN_ADDRESS,
      functionName: 'allowance',
      abi: [
        {
          constant: true,
          inputs: [
            {
              name: '_owner',
              type: 'address',
            },
            {
              name: '_spender',
              type: 'address',
            },
          ],
          name: 'allowance',
          outputs: [
            {
              name: '',
              type: 'uint256',
            },
          ],
          payable: false,
          stateMutability: 'view',
          type: 'function',
        },
      ],
      params: {
        _spender: CONTRACT_ADDRESS_PAY,
        _owner: userAddress,
      },
    };

    const response = await axios.post(`${TOKEN_ADDRESS}/function?chain=${CHAIN_NAME}&function_name=allowance`, {
      abi: options.abi,
      params: { _spender: CONTRACT_ADDRESS_PAY, _owner: userAddress },
    });
    const allowance = ethers.utils.formatEther(response.data, 18);
    console.log(response, allowance);
    if (allowance > 0) {
      return true;
    }
    return false;
  } catch (error) {
    console.error(error);
    return false;
  }
}

async function getUserSnperInfo(userAddress) {
  const options = {
    abi: [
      {
        inputs: [
          {
            internalType: 'address',
            name: '_address',
            type: 'address',
          },
        ],
        name: 'getUserInfo',
        outputs: [
          {
            internalType: 'address',
            name: 'target',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'balance',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'slippage',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
    ],
    contractAddress: CONTRACT_ADDRESS,
    functionName: 'getUserInfo',
  };
  try {
    const response = await axios.post(`${CONTRACT_ADDRESS}/function?chain=${CHAIN_NAME}&function_name=getUserInfo`, {
      abi: options.abi,
      params: { _address: userAddress },
    });
    const balance = ethers.utils.formatEther(response?.data.balance.toString());
    if (response?.data.target === '0x0000000000000000000000000000000000000000') {
      return { target: null, balance, slippage: response?.data.slippage };
    }
    return { target: response?.data.target, balance, slippage: response?.data.slippage };
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function getTokenBalanceForUser(userAddress) {
  const options = {
    abi: [
      {
        inputs: [
          {
            internalType: 'address',
            name: '_owner',
            type: 'address',
          },
        ],
        name: 'balanceOf',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
    ],
    contractAddress: CONTRACT_ADDRESS,
    functionName: 'balanceOf',
  };
  try {
    const response = await axios.post(`${TOKEN_ADDRESS}/function?chain=${CHAIN_NAME}&function_name=balanceOf`, {
      abi: options.abi,
      params: { _owner: userAddress },
    });
    const value = ethers.utils.formatEther(response.data.toString());
    // return '10'; // TODO: Update this with new checking payement
    return (Math.round(value * 100) / 100).toString();
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function getTokenPer100DollarPrice() {
  const options = {
    abi: [
      {
        inputs: [],
        name: 'getTokenPer100Dollar',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
      },
    ],
    contractAddress: CONTRACT_ADDRESS_PAY,
    functionName: 'getTokenPer100Dollar',
  };
  try {
    const response = await axios.post(
      `${CONTRACT_ADDRESS_PAY}/function?chain=${CHAIN_NAME}&function_name=getTokenPer100Dollar`,
      {
        abi: options.abi,
      }
    );
    return Math.round(ethers.utils.formatEther(response.data));
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function getCanUseSniperForUser(userAddress) {
  const options = {
    abi: [
      {
        inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
        name: 'canUseSniper',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
      },
    ],
    contractAddress: CONTRACT_ADDRESS_PAY,
    functionName: 'canUseSniper',
  };
  try {
    const response = await axios.post(
      `${CONTRACT_ADDRESS_PAY}/function?chain=${CHAIN_NAME}&function_name=canUseSniper`,
      {
        abi: options.abi,
        params: { account: userAddress },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function getCanUseBasicUntilForUser(userAddress) {
  const options = {
    abi: [
      {
        inputs: [{ internalType: 'address', name: '', type: 'address' }],
        name: 'basicAccessUntil',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
      },
    ],
    contractAddress: CONTRACT_ADDRESS_PAY,
    functionName: 'basicAccessUntil',
  };
  try {
    const response = await axios.post(
      `${CONTRACT_ADDRESS_PAY}/function?chain=${CHAIN_NAME}&function_name=basicAccessUntil`,
      {
        abi: options.abi,
        params: { '': userAddress },
      }
    );
    console.log(response);
    return response.data * 1000;
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function getCanUsePremiumSniperForUser(userAddress) {
  const options = {
    abi: [
      {
        inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
        name: 'canUsePremiumSniper',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
      },
    ],
    contractAddress: CONTRACT_ADDRESS_PAY,
    functionName: 'canUsePremiumSniper',
  };
  try {
    const response = await axios.post(
      `${CONTRACT_ADDRESS_PAY}/function?chain=${CHAIN_NAME}&function_name=canUsePremiumSniper`,
      {
        abi: options.abi,
        params: { account: userAddress },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function getCanUsePremiumUntilForUser(userAddress) {
  const options = {
    abi: [
      {
        inputs: [{ internalType: 'address', name: '', type: 'address' }],
        name: 'premiumAccessUntil',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
      },
    ],
    contractAddress: CONTRACT_ADDRESS_PAY,
    functionName: 'premiumAccessUntil',
  };
  try {
    const response = await axios.post(
      `${CONTRACT_ADDRESS_PAY}/function?chain=${CHAIN_NAME}&function_name=premiumAccessUntil`,
      {
        abi: options.abi,
        params: { '': userAddress },
      }
    );
    console.log(response);
    return response.data * 1000;
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function getNativeBalanceForUser(userAddress) {
  try {
    const response = await axios.get(`${userAddress}/balance?chain=${CHAIN_NAME}`);
    return ethers.utils.formatEther(response.data.balance);
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function getSnipersForToken(token) {
  const options = {
    abi: [
      {
        inputs: [{ internalType: 'address', name: '_tokenAddress', type: 'address' }],
        name: 'getSnipersForToken',
        outputs: [{ internalType: 'address[]', name: '', type: 'address[]' }],
        stateMutability: 'view',
        type: 'function',
      },
    ],
    contractAddress: CONTRACT_ADDRESS,
    functionName: 'getSnipersForToken',
  };
  try {
    if (token === null) return null;
    const response = await axios.post(
      `${CONTRACT_ADDRESS}/function?chain=${CHAIN_NAME}&function_name=getSnipersForToken`,
      {
        abi: options.abi,
        params: { _tokenAddress: token },
      }
    );
    return response?.data?.length;
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function getTokenMetadataWeb3(token) {
  try {
    if (token === null) return null;
    const response = await axios.get(`/erc20/metadata?chain=${CHAIN_NAME}&addresses=${token}`);
    return response.data[0];
  } catch (error) {
    console.error(error);
    return null;
  }
}
